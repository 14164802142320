import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductService} from '../services/product.service';
import {Subscription} from 'rxjs';


const ERROR_CANNOT_GET_NUMBER_OF_PRODUCTS = 'Cannot get number of products';

@Component({
    selector: 'app-products-overview',
    templateUrl: './products-overview.component.html',
    styleUrls: ['./products-overview.component.css']
})
export class ProductsOverviewComponent implements OnInit, OnDestroy {

    numProducts;
    errorMessage: string;

    catalogueSub: Subscription;
    constructor(private productService: ProductService) {
    }

    ngOnInit() {
        this.catalogueSub = this.productService.getCatalogueInfo().subscribe(
            response => this.numProducts = response.numProducts,
            error => this.errorMessage = ERROR_CANNOT_GET_NUMBER_OF_PRODUCTS
        );
    }

    ngOnDestroy(): void {
        this.catalogueSub.unsubscribe();
    }
}
