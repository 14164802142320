import {Product} from '../shared/models/product';
import {environment} from '../../environments/environment';
import {Params} from '@angular/router';


export const KEY_BACKSPACE = 'Backspace';
export const KEY_ENTER = 'Enter';

export const SERVICE_UNAVAILABLE = 503;
export const SERVICE_UNAVAILABLE_MSG = 'Service unavailable. Please try again later.';

export const INTERNAL_SERVER_ERROR = 500;
export const INTERNAL_ERROR_MSG = 'Something went wrong internally. IT support has been contacted.';


export const ALL_CATEGORY = 'all';
export const DEFAULT_TAX_RATE = 0.1;


export function getDisplayableCategories(product: Product) {
    if (!product || !product.categories) {
        return []
    }
    return product.categories.filter(category => category !== ALL_CATEGORY);
}


export function getPriceError(priceStr: string): {[key: string]: any} {
    const price = parsePrice(priceStr) || 0.0;
    if (price < 0 ) {
        return { negative: 'Price must not be negative' }
    }

    return null;
}

export function parsePrice(priceStr: string) {
    priceStr = priceStr || '';
    const digitsOnlyStr = priceStr.replace(/[^0-9.\-]/g, "");
    if (digitsOnlyStr === '' || digitsOnlyStr == '.' || !digitsOnlyStr.match(/(-)?[0-9.]+/)) {
        return null;
    }
    return +parseFloat(digitsOnlyStr).toFixed(2);
}


export function getTaxRateError(taxRateStr: string) {
    const taxRate = parseTax(taxRateStr) || 0.0
    if (taxRate > 1) {
        return { tooLarge: 'Tax rate must be less than 100%' }
    }
    return null;
}


export function parseTax(taxRateStr: string) {
    taxRateStr = taxRateStr || '';
    const digitsOnlyStr = taxRateStr.replace(/[^0-9.\-]/g, "");
    if (taxRateStr === '') {
        return null;
    }
    return +parseFloat(digitsOnlyStr).toFixed(2)/100.0
}


export function getDefaultProductImageUrl() {
    let imageBucket: string = '';
    if (environment.hostUrl.includes('localhost')) {
        imageBucket = 'wlt-aus-staging.appspot.com'
    } else {
        imageBucket = environment.hostUrl.replace('https://', '');
    }
    return 'https://storage.googleapis.com/' + imageBucket + '/WLT/assets/default-product.png'
}


export function allowNumericKeypressOnly(event: KeyboardEvent, allowDash=true) {
    const key = event.key;

    if (key === KEY_ENTER || key === KEY_BACKSPACE) {
        return;
    }

    // Disable all other special values.
    if ( key.length > 1 ) {
        event.preventDefault();
        return;
    }


    if (allowDash && key === '-') {
        return
    }

    if (key.match(/[0-9.]/)) {
        return;
    }

    // All other cases disable event.
    event.preventDefault();
}


export function getParam(key, params: Params) {
    if (key in params) {
        return params[key];
    }
    return null;
}
