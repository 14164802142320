/**
 * Created by Karl on 9/05/2017.
 */

import {Component} from "@angular/core";

@Component({
    selector: 'admin',
    template:
    `<div class="fill">
        <router-outlet></router-outlet>
    </div>`,
    styleUrls:['../dashboard/admin-dashboard.component.css']
}) export class SetupComponent {

}
