// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    envName: 'staging',
    firebase: {
        apiKey: "AIzaSyD9OfFTl5L1gu-ScqJ1J997DO4wblWjo4A",
        authDomain: "wlt-console-staging.firebaseapp.com",
        projectId: "wlt-console-staging",
        storageBucket: "wlt-console-staging.appspot.com",
        messagingSenderId: "743181937139",
        appId: "1:743181937139:web:5285d9f14e618df2a80a86",
        measurementId: "G-Y4QPJVYE8F"
    },
    hostUrl: 'https://wlt-aus-staging.appspot.com',
    reportingUrl: 'https://vendor-reporting-dot-wlt-aus-staging.appspot.com',
    streamUrl: 'https://wlt-stream-staging.appspot.com.firebaseapp.com',
    gmapsApiKey: 'AIzaSyApFAzmDP-7YhKm9GZEKIV5HhvKBhFltKg',
    disabled: {
        dashboard: false,
        admin: false
    }
};



