<form #productForm="ngForm" class="container product-form" [ngClass]="{'view-only': mode === MODE_VIEW}">
    <div class="row">
        <div class="d-none d-sm-flex col-sm-4 flex-column p-4" style="min-height: 100vh;">
            <div class="row">
                <div class="col-12">
                    <label>{{mode | titlecase}} product</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="name">{{productFormData.name}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="!isResizingImage" class="img-container">
                        <label class="">Product image</label>
                        <img [src]="productFormData.photoUrl" class="img-thumbnail d-block">
                        <input #uploadImage type="file"
                               class="form-control-file d-none"
                               accept="image/*"
                               aria-describedby="fileHelp"
                               (change)="onImageChanged($event.target.files)">
                        <button *ngIf="mode !== MODE_VIEW && !isSaving && !isResizingImage" (click)="uploadImage.click()"
                                class="btn btn-transparent">
                            Upload image
                        </button>
                    </div>
                    <div *ngIf="isResizingImage" class="d-block text-center">
                        <div class="w-50">
                            <app-loader class="d-block mx-auto mt-5" style="height: 50px;"></app-loader>
                            <div class="mt-5"><small>Optimizing image</small></div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="productFormData.websafeKey" class="row mt-1">
                <div class="col-12">
                    <div class="img-container">
                        <label class="">Why Leave Town QR code</label>
                        <img [src]="qrcodeUrl" class="img-thumbnail d-block">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-8 p-4 pr-5" style="min-height: 100vh;">

            <div class="row justify-content-end">
                <div class="col-12 col-sm-4 col-md-6">
                    <div *ngIf="isSaving" class="loader-container align-items-center">
                        <app-loader [stroke]="'var(--charcoal-grey-a50)'"></app-loader>
                        <span class="small ml-4">Saving...</span>
                    </div>
                    <div *ngIf="saveErrorMessage" class="d-inline-flex class error-message">
                        <span>{{saveErrorMessage}}</span>
                    </div>
                </div>
                <div class="col-6 col-sm-4 col-md-3 mt-2 mt-sm-0">
                    <button *ngIf="mode !== MODE_VIEW" class="btn btn-primary w-100"
                            [disabled]="!canSave()"
                            (click)="onSave()">
                        Save
                    </button>
                    <button *ngIf="mode === MODE_VIEW" (click)="edit()" class="btn btn-primary w-100">
                        Edit
                    </button>
                </div>
                <div class="col-6 col-sm-4 col-md-3 mt-2 mt-sm-0">
                    <button class="btn btn-outline-primary w-100" (click)="back()">
                        {{mode === MODE_VIEW ? 'Back' : 'Cancel'}}
                    </button>
                </div>
            </div>

            <!-- [START] Mobile only view image -->
            <div class="row">
                <div class="col-12 d-sm-none">
                    <div *ngIf="!isResizingImage"  class="img-container mobile">
                        <label class="">Product image</label>
                        <img [src]="productFormData.photoUrl" class="img-thumbnail d-block mx-auto">
                        <input
                            #mobUploadImage
                            type="file"
                            accept="image/*"
                            class="form-control-file d-none"
                            aria-describedby="fileHelp"
                            (change)="onImageChanged($event.target.files)">
                        <button *ngIf="mode !== MODE_VIEW && !isSaving && !isResizingImage"
                                (click)="mobUploadImage.click()"
                                class="btn btn-transparent d-block mx-auto">
                            Upload image
                        </button>
                    </div>
                    <div *ngIf="isResizingImage" class="d-block text-center">
                        <div class="w-50 mx-auto">
                            <app-loader class="d-block mx-auto mt-5" style="height: 50px;"></app-loader>
                            <div class="mt-4"><small>Optimizing image</small></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- [END] Mobile Only view image -->

            <div class="row mt-4">
                <div class="col-12">
                    <div class="mx-auto">
                        <label>Name</label>
                        <input id="product-name" name="product-name" class="form-control" type="text"
                               #productName="ngModel"
                               [(ngModel)]="productFormData.name"
                               [readonly]="mode === MODE_VIEW || isSaving" required/>
                        <div *ngIf="productName.invalid && (productName.dirty || productName.touched)">
                            <label *ngIf="productName!.errors!.required" class="error"><sup>*</sup> Product name is
                                required.</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-between mt-4">
                <div class="col-12 col-sm-6">
                    <div class="mx-auto">
                        <label>Price</label>
                        <input id="product-price"
                               #productPrice="ngModel"
                               name="product-price"
                               type="text"
                               class="form-control"
                               (keypress)="onKeypress($event)"
                               (ngModelChange)="onPriceChange($event)"
                               [ngModel]="displayPrice()"
                               [readonly]="mode === MODE_VIEW || isSaving"
                               appPriceValidator
                               required/>
                    </div>
                    <div *ngIf="productPrice.invalid && (productPrice.dirty || productPrice.touched)">

                        <label *ngIf="productPrice!.errors!.required" class="error"><sup>*</sup> Product price is
                            required.</label>
                    </div>

                </div>
                <div class="col-12 col-sm-5 mt-2 mt-sm-0">
                    <div class="mx-auto">
                        <label>Tax (%)</label>
                        <input type="text" name="tax-rate" class="form-control"
                               #productTax="ngModel"
                               appTaxValidator
                               (keypress)="onKeypress($event)"
                               (ngModelChange)="onTaxChange($event)"
                               [ngModel]="displayTax() | number"
                               [readonly]="mode === MODE_VIEW || isSaving"
                               required/>

                        <div *ngIf="productTax.invalid && (productTax.dirty || productTax.touched)">
                            <label *ngIf="productTax!.errors!.required" class="error">
                                <sup>*</sup> Product tax rate is required.
                            </label>
                        </div>

                        <div *ngIf="productTax.invalid && (productTax.dirty || productTax.touched)">
                            <label *ngIf="productTax!.errors!.tooLarge" class="error">
                                <sup>*</sup> Tax rate should be less than 100%
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <div class="mx-auto">
                        <label>Description</label>
                        <textarea type="text" class="form-control" [rows]="5"
                                  name="product-description"
                                  [(ngModel)]="productFormData.description"
                                  [readonly]="mode === MODE_VIEW || isSaving"></textarea>
                    </div>
                </div>
            </div>
            <label class="d-block mt-4">Barcode
                <span *ngIf="isBarcodeAvailable != null"
                      class="badge"
                      [ngClass]="{'badge-success': isBarcodeAvailable, 'badge-danger': !isBarcodeAvailable}">
                    {{ isBarcodeAvailable ? 'Available' : 'Unavailable'}}
                </span>
                <span *ngIf="mode === MODE_EDIT && isBarcodeAvailable == null && productFormData.newBarcode"
                      class="badge badge-primary">
                    Unchanged
                </span>
            </label>
            <div class="row">
                <div class="col-6">
                    <input type="text" name="barcode" class="form-control"
                           #barcode="ngModel"
                           (ngModelChange)="onBarcodeChanged($event)"
                           [ngModel]="mode === MODE_VIEW ? productFormData.barcode : productFormData.newBarcode"
                           [ngClass]="{'unavailable': isBarcodeAvailable === false}"
                           [readonly]="mode === MODE_VIEW || isSaving"
                           required>
                </div>
                <div *ngIf="currentBarcode" class="col-3">
                    <div class="img-container barcode m-0 mx-auto">
                        <img [src]="barcodeUrl(currentBarcode)" class="d-block mx-auto">
                    </div>
                </div>
                <div class="col-3">
                    <button *ngIf="mode !== MODE_VIEW && !productFormData.newBarcode" (click)="generateBarcode()"
                            class="btn btn-transparent w-100">
                        Generate one
                    </button>
                    <button *ngIf="mode !== MODE_VIEW && productFormData.newBarcode"
                            (click)="onClearBarcode()"
                            class="btn btn-transparent w-100">Clear
                    </button>
                </div>
                <div class="col-12 mt-1">
                    <div *ngIf="barcode.invalid && (barcode.dirty || barcode.touched)">
                        <label *ngIf="barcode!.errors!.required" class="error">
                            <sup>*</sup> Product barcode is required.
                        </label>
                    </div>
                    <div *ngIf="isBarcodeAvailable === false && (barcode.dirty || barcode.touched)">
                        <label class="error">
                            <sup>*</sup> Barcode is unavailable.
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="mode === MODE_VIEW" class="row no-gutters mt-4 mt-sm-2">
                <div class="col-4">
                    <div (click)="deleteProduct()" class="btn btn-block btn-transparent text-danger shift-left w-100">
                        Delete product
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
