import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CustomerAccount} from "../../shared/models/admin";

@Component({
    selector: `user-preview`,
    templateUrl: './user-preview.component.html',
    styleUrls: ['./user-preview.component.css']
})
export class UserPreviewComponent {

    @Input()
    user: CustomerAccount;

    @Output()
    onSelect: EventEmitter<CustomerAccount> = new EventEmitter<CustomerAccount>();

    constructor() {
    }
}
