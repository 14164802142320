/**
 * Created by Karl on 6/9/17.
 */
import {NgModule} from "@angular/core";
import {DashboardComponent} from "./dashboard.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import { ReportGeneratorComponent } from '../report-generator/report-generator.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CardsService} from "./cards.service";
import {RequestTileComponent} from "./request-tile/request-tile.component";
import {RequestModalComponent} from "./request-modal/request-modal.component";
import {ReportsModalComponent} from "./reports-modal/reports-modal.component";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        NgbDatepickerModule,
        DashboardRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
    ],
    declarations: [
        DashboardComponent,
        RequestTileComponent,
        ReportGeneratorComponent,
        RequestModalComponent,
        ReportsModalComponent
    ],
    providers: [CardsService]
})
export class DashboardModule {}
