<nav id="page-nav" class="navbar navbar-expand-lg navbar-dark bg-dark" [ngClass]="{'d-none': !showNav }">
    <button *ngIf="isVendorSetup" (click)="showCollapsed = !showCollapsed"
            class="navbar-toggler"
            [ngClass]="{'collapsed': !showCollapsed}"
            type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#">WLT Console</a>
    <div class="navbar-collapse collapse" [ngClass]="{'show': showCollapsed}" id="navbarSupportedContent">
        <ul *ngIf="isVendorSetup" class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/dashboard" class="nav-link" href="/dashboard">Dashboard</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/products" class="nav-link" href="/products">Products</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/store" class="nav-link" href="/store">Stores</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [ngClass]="{'d-none': disabled!.admin}">
                <a routerLink="/admin" class="nav-link" href="/admin">Admin</a>
            </li>
        </ul>
    </div>
    <button *ngIf="isLoggedIn" (click)="logout()"
            class="btn btn-outline-light pull-right my-2 my-sm-0" type="submit">Sign Out
    </button>
</nav>
<router-outlet></router-outlet>
