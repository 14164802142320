<div class="container-fluid">
    <div class="row no-gutters position-relative">
        <div class="d-none d-sm-flex col-sm-4 store-overview">
            <app-store-overview
                class="w-100"
                #overview
                [stores]=stores
                [selectedStore]="selectedStore"
                [currentOffset]="currentOffset"
                [totalStores]="totalStores"
                [pageLoading]="pageLoading"
                (searchedCenter)="onSelectedCenter($event)"
                (onSelectStore)="onSelectStore($event)"
                (pageSelected)="onPageSelected($event)"
                (newStore)="onNewStore($event)">

            </app-store-overview>
        </div>
        <div class="col-12 col-sm-8 position-relative map">
            <agm-map
                #map
                [latitude]="selectedCenter ? selectedCenter.lat() : null"
                [longitude]="selectedCenter ? selectedCenter.lng() : null"
                class="w-100 h-100"
                [zoom]="zoom"
                (centerChange)="onCenterChange($event)"
                (boundsChange)="onBoundsChanged($event)"
                (zoomChange)="onZoomChanged($event)"
                [usePanning]="true"
                [streetViewControl]="false">

                <agm-marker #marker *ngFor="let store of stores"
                            [latitude]="store.lat"
                            [longitude]="store.lng"
                            [iconUrl]="{
                                url: getIconUrl(store),
                                scaledSize: {
                                    width: 24,
                                    height: 32
                                }}"
                            (markerClick)="onSelectStore(store)">
                    <agm-info-window
                        [isOpen]="isSelected(store)">
                        <app-store-controls [store]="store" (storeDeleted)="onStoreDeleted()"></app-store-controls>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    </div>
</div>
