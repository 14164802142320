<div class="row justify-content-center text-center margin-top-50">
    <div class="col-md-6  vendor-not-setup">
        <h1 >Oops! Something went wrong.</h1>
        <p>You currently have a do not have a vendor account. Please contact Why Leave Town for support and tell us your
            email</p>
    </div>
</div>
<div class="row justify-content-center margin-top-50">
    <div class="col-md-6 text-center">
        <p><strong>Support Contact:</strong> Karl Nelson</p>
        <p><strong>Contact Email:</strong> knelson@tilly.cc</p>
        <p><strong>Contact Number:</strong> 0437 484 265</p>
    </div>
</div>
