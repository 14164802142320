/**
 * Created by Karl on 22/04/2017.
 */

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule, CurrencyPipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SetupComponent} from "./setup/setup.component";
import {AdminRoutingModule} from "./admin-routing.module";
import {InviteUserComponent} from "./mgmt/user/invite-user.component";
import {VerifyInviteComponent} from "./setup/user/verify-user.component";
import {SignUpComponent} from "./setup/user/signup.component";
import {VerifyEmailComponent} from "./setup/user/verify-email.component";
import {WelcomeUserComponent} from "./mgmt/user/welcome-user.component";
import {AdminDashboardComponent} from "./dashboard/admin-dashboard.component";
import {VendorNotSetupComponent} from './vendor-not-setup/vendor-not-setup.component';
import {UserPreviewComponent} from "./user-preview/user-preview.component";
import {AdminOverviewComponent} from "./overview/admin-overview.component";
import {CustomerService} from "./customer.service";
import {SharedModule} from "../shared/shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {UserFormComponent} from "./user-form/user-form.component";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        AdminRoutingModule,
        FontAwesomeModule,
        SharedModule,
        ReactiveFormsModule
    ],
    declarations: [
        SetupComponent,
        AdminDashboardComponent,
        AdminOverviewComponent,
        UserFormComponent,
        SignUpComponent,
        InviteUserComponent,
        VerifyInviteComponent,
        VerifyEmailComponent,
        WelcomeUserComponent,
        VendorNotSetupComponent,
        UserPreviewComponent
    ],
    exports: [
        SignUpComponent
    ],
    providers: [
        CustomerService,
        CurrencyPipe
    ],
    bootstrap: []
}) export class AdminModule {}
