
import {environment} from "../environments/environment";
import {isValidEAN13, isValidUPCA} from './product/barcode-utils';
import {buildUrl} from './utils';

export class GAPI {

    static apiPath(): string {
        return environment.hostUrl
    }

    static buildApiPath(...segments: string[]) {
        let ret = this.apiPath() + "/";
        return ret.concat(this.buildRelPath(segments));
    }

    private static buildRelPath(segments) {
        let ret = "";
        for (let i=0; i<segments.length -1; i++) {
            ret = ret.concat(segments[i], "/");
        }
        return ret.concat(segments[segments.length-1]);
    }

    static addQueries(path: string, kvps: {[key: string]: string}): string {
        if ( Object.keys(kvps).length === 0 ){
            return path;
        }
        let ret = path.concat("?");
        for ( let key in kvps ) {
            ret = ret.concat(key, "=", kvps[key], "&")
        }
        return ret.substring(0, ret.length-1)
    }

    // TODO Deprecated
    static staticFile(...segments: string[]) {
        return environment.hostUrl + "static/".concat(this.buildRelPath(segments));
    }

    // TODO Deprecated
    static defaultImg() {
        return "https://storage.googleapis.com/wlt-aus-staging.appspot.com/default-imgs/default_img.jpg"
    }

    static qrCode(webkey: string) {
        return GAPI.addQueries(GAPI.buildApiPath('qr'), {websafeKey: webkey});
    }

    static barcode(barcode: string): string {
        let type: string;
        if (isValidUPCA(barcode)) {
            type = 'upca'
        } else if (isValidEAN13(barcode)) {
            type = 'ean13'
        } else {
            type = 'code128'
        }
        return buildUrl(environment.hostUrl, ['barcode'], {barcode: barcode, type: type})
    }
}
