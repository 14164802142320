import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {LoadStatuses} from "../dashboard";
import {FormControl, FormGroup} from "@angular/forms";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date";
import {NgbActiveModal, NgbCalendar} from "@ng-bootstrap/ng-bootstrap";
import {AdminService} from "../../admin/admin.service";
import {ReportingService} from "../../reporting.service";
import {HttpErrorResponse} from "@angular/common/http";
import {INTERNAL_ERROR_MSG, INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE, SERVICE_UNAVAILABLE_MSG} from "../../constants";


@Component({
    selector: 'reports-modal',
    templateUrl: './reports-modal.component.html',
    styleUrls: ['./reports-modal.component.css']
})
export class ReportsModalComponent implements OnInit {


    @Input()
    loadStatuses: LoadStatuses;

    @ViewChild("dp")
    datePicker

    errorMessage: string;


    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;

    checkboxGroup = new FormGroup({
        'pending': new FormControl(false),
        'approved': new FormControl(false),
        'rejected': new FormControl(false)
    })

    isLoading = false;

    constructor(public activeModal: NgbActiveModal, public calendar: NgbCalendar, private adminService: AdminService, private reportingService: ReportingService) {
    }

    ngOnInit(): void {
        if (this.loadStatuses != null) {
            this.checkboxGroup.setValue(this.loadStatuses)
        }
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isInside(date: NgbDate) {
        if (!date) {
            return false;
        }
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isRange(date: NgbDate) {
        if (!date) {
            return false;
        }
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    toDateStr(date: NgbDate) {
        if(!date) {
            return '';
        }
        const paddedMonth = `${date.month}`.padStart(2, '0');
        const paddedDay = `${date.day}`.padStart(2, '0');

        return `${date.year}${paddedMonth}${paddedDay}`;
    }

    getReportName(fromDate: NgbDate, toDate: NgbDate, ext='.xlsx') {
        const vendorAccount = this.adminService.vendorAccount;
        if (vendorAccount == null) {
            return '';
        }
        const vendorName = vendorAccount!.vendor!.name!.replace(/\s/g, '_') || '';
        const fromDateStr = this.toDateStr(fromDate);
        const toDateStr = this.toDateStr(toDate);
        if (!fromDateStr && !toDateStr) {
            return  vendorName + ext;
        }

        if (!fromDateStr) {
            throw new Error('Must have a from date but not necessarily a to date.')
        }

        if (!toDateStr) {
            return fromDateStr + '-' + vendorName + ext;
        }

        return fromDateStr + '-' + toDateStr + '-' + vendorName + ext
    }

    generateReport() {
        this.isLoading = true;
        const reportName = this.getReportName(this.fromDate, this.toDate);
        this.reportingService.generateLoadsReport(this.checkboxGroup.value, this.fromDate, this.toDate).subscribe(
            report => this.downloadReport(reportName, report.contentType, report.content),
            error => this.handleError(error)
        );
        // const blob = new Blob([arrayBuffer], {type: contentType});

    }

    downloadReport(reportName: string, contentType: string, arrayBuffer: any) {
        this.isLoading = false;
        const blob = new Blob([arrayBuffer], {type: contentType});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
    }

    handleError(error: any) {
        this.isLoading = false;
        if (error instanceof HttpErrorResponse) {
            this.handleHttpError(<HttpErrorResponse>error);
            return;
        }

        this.errorMessage = SERVICE_UNAVAILABLE_MSG;

    }

    private handleHttpError(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
            return;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            this.errorMessage = INTERNAL_ERROR_MSG;
            return;
        }
        console.log(error);
        this.errorMessage = error!.error!.error!.message || SERVICE_UNAVAILABLE_MSG;
    }

}
