<form *ngIf="!isDownloading" #reportForm="ngForm" class="row report-form">
    <div class="col-12 text-center flex-column p-4">
        <h5>Transaction report</h5>
        <p class="description">Get a report of all Why Leave Town transactions within the date range</p>
        <div class="date-container w-100">
            <ngb-datepicker #dp (select)="onDateSelection($event)"
                            class="mt-2"
                            [displayMonths]="isSmallDevice ? 1 : 2"
                            [dayTemplate]="t"
                            outsideDays="hidden">
            </ngb-datepicker>
            <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
            </ng-template>
        </div>
        <button [disabled]="!toDate && !fromDate"
                (click)="generateReport()"
                class="btn btn-primary mt-4 w-100">
            Generate report
        </button>


        <div *ngIf="!isDownloading && errorMessage" class="row mt-1">
            <div class="col-12 text-left">
                <label class="error"><sup>*</sup>{{errorMessage}}</label>
            </div>
        </div>
    </div>
</form>
<div *ngIf="isDownloading" class="loading">
    <div class="position-relative m-auto">
        <div class="container">
            <app-loader [width]="'75px'" [height]="'75px'"></app-loader>
        </div>
        <div>Downloading report...</div>
    </div>
</div>
