<div class="overview">
    <div class="row">
        <div class="col-12">
            <h2>Products</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>Here's where you can manage your product catalogue on Why Leave Town.</p>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <span *ngIf="numProducts">{{numProducts}} products</span>
        </div>
    </div>
    <!--<div class="row no-gutters">-->
        <!--<div class="col-12">-->
            <!--<button class="btn btn-transparent">Export</button>-->
        <!--</div>-->
    <!--</div>-->
</div>
