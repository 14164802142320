<form #resetPasswordForm="ngForm" class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-5 d-flex align-items-center"
             style="min-height: calc(100vh - 56px)">
            <div class="reset-password shift-up-1 p-2 p-sm-5">
                <div class="d-block w-100 text-center">
                    <h1 class="d-block mx-auto">👋</h1>
                    <h2>Welcome</h2>
                    <p class="d-block mx-auto mt-1">Set up your own password to log into the vendor console in the
                        future</p>
                </div>
                <div class="form-group mt-5 px-2">
                    <label class="" for="email-input">Password</label>
                    <input #passwordInput="ngModel"
                           class="form-control"
                           id="email-input"
                           type="password"
                           name="password"
                           minlength="5"
                           [(ngModel)]="password"
                           ngModel
                           required>
                    <div *ngIf="passwordInput.errors && (passwordInput.dirty || passwordInput.touched)">
                        <label *ngIf="passwordInput!.errors!.minlength" class="error">
                            <sup>*</sup> Password must be more than {{passwordInput!.errors!.minlength!.requiredLength - 1}} characters.
                        </label>
                    </div>
                </div>
                <div class="form-group mt-1 px-0 px-2">
                    <label for="password-input">Confirm Password</label>
                    <input
                        [(ngModel)]="confirmPassword"
                        #confirmPasswordInput="ngModel"
                        class="form-control"
                        id="password-input"
                        type="password"
                        name="confirmPassword"
                        minlength="5"
                        required>

                    <div *ngIf="confirmPasswordInput.errors && (confirmPasswordInput.dirty || confirmPasswordInput.touched)">
                        <label *ngIf="confirmPasswordInput!.errors!.minlength" class="error">
                            <sup>*</sup> Password must be more than {{confirmPasswordInput!.errors!.minlength!.requiredLength - 1}} characters.
                        </label>
                    </div>
                    <div *ngIf="!confirmPasswordInput.errors && confirmPassword && !passwordsMatch" class="form-group px-2">
                        <label class="error">Passwords do not currently match.</label>
                    </div>
                </div>

                <div class="form-group px-2">
                    <label *ngIf="errorMessage" class="error">
                        <sup>*</sup> {{errorMessage}}
                    </label>
                </div>
                <div class="row mt-4 mb-4 px-2">
                    <div class="col-12">
                        <button [disabled]="resetPasswordForm.form.invalid || !passwordsMatch || isSubmitting"
                                (click)="resetPassword()" class="btn btn-primary w-100">
                            Create password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

