import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AdminService} from "./admin.service";
import {CustomerAccount, CustomerSearchResponse} from "../shared/models/admin";
import {switchMap, delay, first, tap, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {buildUrl, getAuthHeaders} from "../utils";
import {environment} from "../../environments/environment";


@Injectable()
export class CustomerService {

    onUpdateSuccess: EventEmitter<CustomerAccount> = new EventEmitter<CustomerAccount>();


    constructor(private http: HttpClient, private adminService: AdminService) {
    }

    searchCustomers(searchText: string, offset: number = 0): Observable<CustomerSearchResponse> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getCustomersFromServer(token, searchText, offset)),
            first()
        )
    }

    private getCustomersFromServer(token: string, searchText, offset: number = 0, pageSize: number = 10): Observable<CustomerSearchResponse> {
        const url = buildUrl(environment.hostUrl, ['admin', 'search_customers'], {});
        const body = {searchText: searchText, offset: offset, pageSize: pageSize};
        return this.http.post<CustomerSearchResponse>(url, body, {headers: getAuthHeaders(token)});
    }

    createCustomer(customer: CustomerAccount) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.createCustomerOnServer(token, customer)),
            first()
        )
    }

    private createCustomerOnServer(token: string, customer: CustomerAccount): Observable<CustomerAccount> {
        const url = buildUrl(environment.hostUrl, ['admin', 'create_customer'], {});
        return this.http.post<CustomerAccount>(url, customer, {headers: getAuthHeaders(token)}).pipe(
            tap(acct => this.onUpdateSuccess.emit(acct))
        )
    }

    updateCustomer(id: string, customer: CustomerAccount) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.updateCustomerOnServer(token, id, customer)),
            first()
        )
    }

    private updateCustomerOnServer(token: string, id: string, updated: CustomerAccount) {
        const url = buildUrl(environment.hostUrl, ['admin', 'update_customer'], {});
        updated.id = id
        return this.http.post<CustomerAccount>(url, updated, {headers: getAuthHeaders(token)}).pipe(
            tap(acct => this.onUpdateSuccess.emit(acct))
        )
    }
}
