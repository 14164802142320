<div class="container-fluid">
    <div class="row">
<!--                    <div class="d-flex flex-row w-100 h-100 align-items-center" >-->
<!--                        <app-report-generator class="d-flex"></app-report-generator>-->
<!--                    </div>-->
        <div class="d-none d-md-flex col-md-4 dashboard-overview">
            <div class="overview">
                <div class="row">
                    <div class="col-12">
                        <h2>Dashboard</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="description">
                            Manage card requests and generating reports the latest activity.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8">
            <div class="row">
                <div class="col-12">
                    <h2 class="m-overview">Load Requests</h2>
                </div>
            </div>
            <div class="row align-items-center">
                <form [formGroup]="checkboxGroup" class="col-6 col-sm-8">
                    <div class="form-check-inline align-middle">
                        <input formControlName="pending" id="pending-check" type="checkbox" class="form-check-input">
                        <label for="pending-check" class="form-check-label">Pending</label>
                    </div>
                    <div class="ml-1 form-check-inline align-middle">
                        <input formControlName="approved" id="approved-check" type="checkbox" class="form-check-input">
                        <label for="approved-check" class="form-check-label">Approved</label>
                    </div>
                    <div class="ml-1 form-check-inline align-middle">
                        <input formControlName="rejected" id="rejected-check" type="checkbox" class="form-check-input">
                        <label for="rejected-check" class="form-check-label">Rejected</label>
                    </div>
                </form>
                <div class="col-6 col-sm-4 text-right">
                    <button class="btn btn-transparent mr-3" (click)="openReportModal()">Generate Report</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-page-indicator [minOffset]="minOffset"
                                        [currentOffset]="currentOffset"
                                        [numResults]="numResults"
                                        [maxIndicators]="isXSScreen()? 3 : 5"
                                        [pageSize]="pageSize"
                                        (pageSelected)="pageSelected$.next($event)"
                                        (minOffsetChanged)="minOffset = $event">
                    </app-page-indicator>
                </div>
            </div>
            <div *ngIf="!isLoadingPage && numResults > 0" class="row mt-2 request-page">
                <div class="col-12 h-100">
                    <ul>
                        <li *ngFor="let request of requests">
                            <request-tile [request]="request"
                                          (approve)="onApprove($event)"
                                          (reject)="onReject($event)"
                                          (onDrillDown)="onDrillDown($event)"
                                          class="w-100"></request-tile>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="!isLoadingPage && requests.length == 0" class="centered-container">
                <div class="content">
                    <div class="row mx-auto text-center justify-content-center">
                        <div class="col-8 no-results-found">
                            <fa-icon [icon]="faSmile" size="4x"></fa-icon>
                            <div class="message mx-auto mt-1">
                                There are currently no load requests. Just relax...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
