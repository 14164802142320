<div class="store d-flex w-100 store-container flex-row" [class.selected]="selected">
    <div class="d-flex w-100 flex-column">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="short-address d-flex">
                <span class="vendor">{{store.vendor}} </span><span class="ml-1"> {{store | shortAddress}}</span>
            </div>
            <div class="d-flex">
                <label [ngClass]="{'active': store.active, 'inactive': !store.active}">
                    {{store.active ? 'Active' : 'Inactive'}}
                </label>
            </div>
        </div>
        <div class="store-info d-flex flex-row w-100 justify-content-between">
            <div class="d-flex purchases">{{purchaseSummaryStr}}</div>
            <button (click)="openPurchaseStream()" class="d-flex btn-transparent">Stream</button>
        </div>
    </div>
</div>
