<div *ngIf="isSubmitting" class="container d-flex align-items-center justify-content-center" style="min-height: calc(100vh - 56px)">
    <div class="d-flex flex-column">
        <app-loader class="d-block mx-auto" style="width: 50px; height: 50px"
                    [stroke]="'var(--charcoal-grey-a50)'"></app-loader>
        <div class="d-block text-center mt-1">
            <p class="pl-1 lead text-center">Logging in</p>
        </div>
    </div>
</div>
<form *ngIf="!isSubmitting" #loginForm="ngForm" class="container from-top">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-5 d-flex align-items-center"
             style="min-height: calc(100vh - 56px)">
            <div class="login-container shift-up-1 p-2 p-sm-5">
                <div class="img-container px-2">
                    <img class="d-block mx-auto login-img" src="/assets/imgs/wlt-logo.jpg"/>
                    <label class="d-block mx-auto mt-1">Vendor console</label>
                </div>
                <div class="form-group mt-5 px-2">
                    <label class="" for="email-input">Your email</label>
                    <input #emailInput="ngModel"
                           class="form-control"
                           id="email-input"
                           type="email"
                           name="email"
                           [(ngModel)]="email"
                           ngModel
                           email
                           required>
                    <div *ngIf="emailInput.errors && (emailInput.dirty || emailInput.touched)">
                        <label *ngIf="emailInput!.errors!.required" class="error">
                            <sup>*</sup> Please enter your email address.
                        </label>
                        <label *ngIf="!emailInput!.errors!.required && emailInput!.errors!.email" class="error">
                            <sup>*</sup> Email address is an invalid format.
                        </label>
                    </div>
                </div>
                <div class="form-group mt-1 px-0 px-2">
                    <label for="password-input">Password</label>
                    <input
                        [(ngModel)]="password"
                        #passwordInput="ngModel"
                        class="form-control"
                        id="password-input"
                        type="password"
                        name="password"
                        minlength="5"
                        required>

                    <div *ngIf="passwordInput.errors && (passwordInput.dirty || passwordInput.touched)">
                        <label *ngIf="passwordInput!.errors!.required" class="error">
                            <sup>*</sup> Please enter your password.
                        </label>
                        <label *ngIf="passwordInput!.errors!.minlength" class="error">
                            <sup>*</sup> Password must be more than {{passwordInput!.errors!.minlength!.requiredLength -
                            1}} characters.
                        </label>
                    </div>
                </div>
                <div class="form-group px-2">
                    <label *ngIf="errorMessage" class="error">
                        <sup>*</sup> {{errorMessage}}
                    </label>
                </div>
                <div class="row mt-4 mb-4 px-2">
                    <div class="col-12">
                        <button [disabled]="loginForm.form.invalid || isSubmitting" (click)="login()"
                                class="btn btn-primary w-100">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

