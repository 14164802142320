/**
 * Created by Karl on 11/05/2017.
 */
import {Routes, RouterModule} from "@angular/router";
import {SetupComponent} from "./setup/setup.component";
import {NgModule} from "@angular/core";
import {VerifyInviteComponent} from "./setup/user/verify-user.component";
import {VerifyEmailComponent} from "./setup/user/verify-email.component";
import {WelcomeUserComponent} from "./mgmt/user/welcome-user.component";
import {AdminDashboardComponent} from "./dashboard/admin-dashboard.component";
import {VendorSetupGuard} from "../vendor-setup-guard/vendor-setup.guard";
import {VendorNotSetupComponent} from './vendor-not-setup/vendor-not-setup.component';


const routes: Routes = [
    {
        path: 'setup', component: SetupComponent,
        children: [
            { path: 'verify-email', component: VerifyEmailComponent},
            { path: 'verify/:pendingUserKey', component: VerifyInviteComponent},
            { path: 'welcome', component: WelcomeUserComponent}
        ],
    },
    {
        path: 'admin', component: AdminDashboardComponent, canActivate: [VendorSetupGuard]
    },
    {
        path: 'vendor-not-setup', component: VendorNotSetupComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class AdminRoutingModule {}
