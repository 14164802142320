<div class="wrapper">
    <nav id="sidebar" class="d-none d-sm-block position-fixed" role="navigation">
        <div class="sidebar-header"></div>
        <ul class="components">
            <h1 class="title">Direct API</h1>
            <li (click)="navigateTo('introduction')" [class.active]="currentUrl === '/api#introduction'">
                <a>Introduction</a>
            </li>
            <li (click)="navigateTo('authorization')" [class.active]="currentUrl === '/api#authorization'">
                <a>Authorization</a>
                <ul>
                    <li (click)="navigateTo('access_token'); $event.stopPropagation()"
                        [class.active]="currentUrl === '/api#access_token'">
                        <a>Retrieving your access token</a>
                    </li>
                </ul>
            </li>
            <li (click)="navigateTo('errors')" [class.active]="currentUrl === '/api#errors'">
                <a>Errors</a>
            </li>
            <li (click)="navigateTo('products')" [class.active]="currentUrl === '/api#products'">
                <a>Products</a>
                <ul>
                    <li (click)="navigateTo('syncing_catalogues'); $event.stopPropagation()"
                        [class.active]="currentUrl === '/api#syncing_catalogues'">
                        <a>Syncing your catalogue</a>
                    </li>
                    <li (click)="navigateTo('get_products'); $event.stopPropagation()"
                        [class.active]="currentUrl === '/api#get_products'">
                        <a>Getting products</a>
                    </li>
                    <li (click)="navigateTo('delete_products'); $event.stopPropagation()"
                        [class.active]="currentUrl === '/api#delete_products'">
                        <a>Deleting products</a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="coming-soon">Stores
                    <small>(Coming Soon)</small>
                </a>
            </li>
            <li >
                <a class="coming-soon">Sales
                    <small>(Coming Soon)</small>
                </a>
            </li>
            <li>
                <a class="coming-soon">Purchases
                    <small>(Coming Soon)</small>
                </a>
            </li>
        </ul>
    </nav>
    <div scrollSpy [topOffset]="offsetPixels" (onIdChanged)="onIdChange($event)"
         class="content align-sidebar">
        <div id="introduction">
            <h1 class="section-title">Introduction</h1>
            <div class="row">
                <div class="col-12">
                    <h2 class="section-header">Why Leave Town Direct API</h2>
                    <div class="section-content">
                        The Direct API ensures you can synchronise your store operations onto Why Leave Town.
                        The API is still in beta, so we can accept requests for changes at any point in time.
                        We will also be updating the API frequently.
                        We will publish any breaking changes to those who connect to the API,
                        and will update accordingly after a deprecation process.
                        Why Leave Town recognises that you are a valued client and will ensure your connectivity will not go
                        down.
                        Why Leave Town will be continuing to roll out new services. All clients currently connected to staging
                        will be upgraded to UAT when the environment is live.
                        <h3 class="mt-3">Base URL</h3>
                        <a class="d-block mb-5" style="color: var(--nice-blue)">https://direct.wlt-aus-staging.appspot.com</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="authorization" class="section">
            <h1 class="section-title">Authorization</h1>
            <div class="row">
                <div class="col-12">
                    <div id="access_token" class="section">
                        <h2 class="section-header">Access Token</h2>
                        <p class="section-content">When you have been signed up to Why Leave Town and you want to access the API,
                            you will be allocated a service account, and sent to you in the form of a JSON file.
                            You will use the values in this file to retrieve an access token, which you will use in the
                            <code>Authorization</code> header with the value format as <code>"Bearer: token"</code>.
                        </p>
                        <ul class="method-list">
                            <h3 class="method-title">Retrieving your access token</h3>
                            <hr>
                            <h4 class="method-uri"><strong>POST</strong> /auth/get_access_token</h4>
                            <hr>
                            <h4>HEADERS</h4>
                            <ul class="method-objects">
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">Content-Type</span>
                                        <span class="required">required</span>
                                    </h3>
                                    <p class="method-object-description">
                                        "application/json"
                                    </p>
                                </li>
                            </ul>
                            <h4>ARGUMENTS</h4>
                            <ul class="method-objects">
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">vendorId</span>
                                        <span class="type">string</span>
                                        <span class="required">required</span>
                                    </h3>
                                    <p class="method-object-description">The websafe vendor id assinged by Why Leave Town. This
                                        should be present in the JSON file.</p>
                                </li>
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">serviceAccountId</span>
                                        <span class="type">string</span>
                                        <span class="required">required</span>
                                    </h3>
                                    <p class="method-object-description">The service account that wishes to access the
                                        API.
                                        This distinguishes access between services. If you require another machine to
                                        access
                                        the API, you should request another service account as this provides us with
                                        granularity on which machine is accessing our system. This should be present in
                                        the
                                        JSON file.
                                    </p>
                                </li>
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">accessKey</span>
                                        <span class="type">string</span>
                                        <span class="required">required</span>
                                    </h3>
                                    <p class="method-object-description">
                                        The access key for this service account.
                                        This should be present in the JSON file.
                                    </p>
                                </li>
                            </ul>
                            <h4>RESPONSE</h4>
                            <ul class="method-objects">
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">accessToken</span>
                                        <span class="type">string</span>
                                    </h3>
                                    <p class="method-object-description">
                                        The granted JWT access token to access the API.
                                    </p>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="errors" class="section">
            <h1 class="section-title">Errors</h1>
            <div class="row">
                <div class="col-12">
                    <h2 class="section-header">Client Errors 4xx</h2>
                    <p class="section-content">
                        When a client provides incorrect information, the server will return a 4xx client error
                        depending on the error itself.
                        The resulting message will be JSON with the arguments below:
                    </p>
                    <ul class="method-list">
                        <li class="method-item">
                            <h3 class="method-title">Client Error Example</h3>
                            <ul class="method-objects">
                                <li class="method-object">
                                    <h3 class="method-object-label">
                                        <span class="name">error</span>
                                        <span class="type">object</span>
                                    </h3>
                                    <p class="method-object-description">The error object including message</p>
                                    <div class="method-object-child">
                                        <h4>Error Object</h4>
                                        <ul class="method-objects">
                                            <li class="method-object">
                                                <h3 class="method-object-label">
                                                    <span class="name">code</span>
                                                    <span class="type">integer</span>
                                                    <span class="required">guaranteed</span>
                                                </h3>
                                                <p class="method-object-description">The HTTP code</p>
                                            </li>
                                            <li class="method-object">
                                                <h3 class="method-object-label">
                                                    <span class="name">message</span>
                                                    <span class="type">string</span>
                                                    <span class="required">guaranteed</span>
                                                </h3>
                                                <p class="method-object-description">The reason for the error.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="products" class="section">
            <h1 class="section-title">Products API</h1>
            <div class="row">
                <div class="col-12">
                    <!-- [START] Sync product catalogue -->
                    <div id="syncing_catalogues" class="section">
                        <h2 class="section-header">Syncing your product catalogue</h2>
                        <p class="section-content">To sync your product catalogue from an existing ERP system,
                            you can either, sync product by their erp id (preferred) or by the product barcode set.
                            All requests are considered atomic. Each transaction is locked optimistically.
                            It is recommended that you sync after your changes have been committed to prevent
                            any mismatches between Why Leave Town and your system.
                        </p>
                        <p class="alert alert-danger"><strong>Restriction: </strong> 32MB request size.</p>
                        <p class="alert alert-info"><strong>Recommendation: </strong>
                            Break up your snapshot into batches of 500.
                        </p>
                        <ul class="method-list">
                            <li class="method-item">
                                <h3 class="method-title">Sync by ERP Ids</h3>
                                <hr>
                                <h4 class="method-uri"><strong>POST</strong> /products/sync_products_by_erp_ids</h4>
                                <hr>
                                <h4>HEADERS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">Content-Type</span>
                                            <span class="required">required</span>
                                        </h3>
                                        <p class="method-object-description">
                                            "application/json"
                                        </p>
                                    </li>
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">Authorization</span>
                                            <span class="required">required</span>
                                        </h3>
                                        <p class="method-object-description">
                                            "Bearer: &lt;token&gt;"
                                        </p>
                                    </li>
                                </ul>
                                <h4 class="method-object-title">ARGUMENTS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product messages defined
                                            below.</p>
                                        <div class="method-object-child">
                                            <h4>Product Object</h4>
                                            <ul class="method-objects">
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">name</span>
                                                        <span class="type">string</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">The name of the product that
                                                        appear as a title
                                                        when a product is scanned.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">description</span>
                                                        <span class="type">string</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">The scrollable body of text
                                                        that
                                                        appears underneath
                                                        the product price and quantity. Repeat the name if you do not
                                                        have a
                                                        long description.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">categories</span>
                                                        <span class="type">array</span>
                                                    </h3>
                                                    <p class="method-object-description">A list of categories (strings)
                                                        used to define the product. E.g. T-shirt.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">taxRate</span>
                                                        <span class="type">number</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">A number between 0 and 1
                                                        inclusive, signifying the tax rate paid on the product.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">price</span>
                                                        <span class="type">number</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">A number signifying the
                                                        price,<strong>tax inclusive</strong>, that should be paid by a
                                                        customer.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">photoUrl</span>
                                                        <span class="type">string</span>
                                                    </h3>
                                                    <p class="method-object-description">The image url of the product
                                                        from
                                                        an online source.
                                                        If one is not provided,
                                                        the default Why Leave Town product image url will be used.
                                                    </p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">barcodes</span>
                                                        <span class="type">array</span>
                                                    </h3>
                                                    <p class="method-object-description">A list of barcodes (strings),
                                                        including aliases
                                                        used to identify the product.
                                                    </p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">erpId</span>
                                                        <span class="type">string</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">The unique Id in your product
                                                        catalogue.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <hr>
                                <h4 class="method-response-title">RESPONSE</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product objects with additional
                                            fields</p>
                                    </li>
                                </ul>
                                <div class="method-object-child">
                                    <h4>Product Additional Fields</h4>
                                    <ul class="method-objects">
                                        <li class="method-object">
                                            <h3 class="method-object-label">
                                                <span class="name">websafeKey</span>
                                                <span class="type">string</span>
                                            </h3>
                                            <p class="method-object-description">The url safe Why Leave Town Id given to the
                                                product.</p>
                                        </li>
                                        <li class="method-object">
                                            <h3 class="method-object-label">
                                                <span class="name">barcode</span>
                                                <span class="type">string</span>
                                            </h3>
                                            <p class="method-object-description">The default barcode used. This is the
                                                barcode that was supplied first in the <code>barcodes</code> argument.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="method-item">
                                <h3 class="method-title">Sync by Barcodes</h3>
                                <hr>
                                <h4 class="method-uri"><strong>POST</strong> /products/sync_products_by_barcodes</h4>
                                <hr>
                                <h4>HEADERS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">Content-Type</span>
                                            <span class="required">required</span>
                                        </h3>
                                        <p class="method-object-description">
                                            "application/json"
                                        </p>
                                    </li>
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">Authorization</span>
                                            <span class="required">required</span>
                                        </h3>
                                        <p class="method-object-description">
                                            "Bearer: &lt;token&gt;"
                                        </p>
                                    </li>
                                </ul>
                                <h4 class="method-objects-title">ARGUMENTS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product messages defined
                                            below.</p>
                                        <div class="method-object-child">
                                            <h4>Product Object</h4>
                                            <ul class="method-objects">
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">name</span>
                                                        <span class="type">string</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">The name of the product that
                                                        appear as a title
                                                        when a product is scanned.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">description</span>
                                                        <span class="type">string</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">The scrollable body of text
                                                        that
                                                        appears underneath
                                                        the product price and quantity. Repeat the name if you do not
                                                        have a
                                                        long description.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">categories</span>
                                                        <span class="type">array</span>
                                                    </h3>
                                                    <p class="method-object-description">A list of categories (strings)
                                                        used to define the product. E.g. T-shirt.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">taxRate</span>
                                                        <span class="type">number</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">A number between 0 and 1
                                                        inclusive, signifying the tax rate paid on the product.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">price</span>
                                                        <span class="type">number</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">A number signifying the price,
                                                        <strong>tax inclusive</strong>,
                                                        that should be paid by a customer.</p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">photoUrl</span>
                                                        <span class="type">string</span>
                                                    </h3>
                                                    <p class="method-object-description">The image url of the product
                                                        from
                                                        an online source.
                                                        If one is not provided,
                                                        the default Why Leave Town product image url will be used.
                                                    </p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">barcodes</span>
                                                        <span class="type">array</span>
                                                        <span class="required">required</span>
                                                    </h3>
                                                    <p class="method-object-description">A list of barcodes (strings),
                                                        including aliases used to identify the product. There must be at
                                                        least one barcode.
                                                    </p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">barcodes</span>
                                                        <span class="type">array</span>
                                                    </h3>
                                                    <p class="method-object-description">A list of barcodes (strings),
                                                        including aliases
                                                        used to identify the product.
                                                    </p>
                                                </li>
                                                <li class="method-object">
                                                    <h3 class="method-object-label">
                                                        <span class="name">erpId</span>
                                                        <span class="type">string</span>
                                                        <span class="optional">optional</span>
                                                    </h3>
                                                    <p class="method-object-description">The unique Id in your product
                                                        catalogue.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <hr>
                                <h4 class="method-response-title">RESPONSE</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product objects, the same as the
                                            previous method.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- [END] Sync product catalogue -->
                    <!-- [START] GET Product by ERP -->
                    <div id="get_products" class="section">
                        <h2 class="section-header">Getting products</h2>
                        <p class="section-content">You can query products with your own ERP Ids. We will also expose the
                            ability to do this by barcode and Why Leave Town ids at a later date.</p>
                        <p class="alert alert-danger"><strong>Restriction: </strong>Can only request 1000 products at a
                            time.</p>
                        <p class="alert alert-info"><strong>Recommendation:</strong> Perform multiple get requests.</p>
                        <ul class="method-list">
                            <li class="method-item">
                                <h3 class="method-title">Get products by ERP Ids</h3>
                                <hr>
                                <h4 class="method-uri"><strong>GET</strong> /products/get_products_by_erp_ids?erpId=id1&amp;erpId=...
                                </h4>
                                <hr>
                                <h4 class="method-objects-title">PARAMETERS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">erpId</span>
                                            <span class="type">string</span>
                                            <span class="repeated">repeated</span>
                                        </h3>
                                        <p class="method-object-description">The unique Id in your product
                                            catalogue.</p>
                                    </li>
                                </ul>
                                <hr>
                                <h4 class="method-response-title">RESPONSE</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product objects, the same as the
                                            previous method.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- [END] GET Product by ERP -->
                    <!-- [START] DELETE Product by ERP -->
                    <div id="delete_products" class="section">
                        <h2 class="section-header">Deleting products</h2>
                        <p class="section-content">You can delete products with your own ERP ids with our API.</p>
                        <p class="alert alert-danger"><strong>Restriction: </strong>Can only request 1000 products at a
                            time.</p>
                        <p class="alert alert-info"><strong>Recommendation:</strong> Perform multiple get requests.</p>
                        <ul class="method-list">
                            <li class="method-item">
                                <h3 class="method-title">Delete products by ERP Ids</h3>
                                <hr>
                                <h4 class="method-uri"><strong>DELETE</strong>
                                    /products/delete_products_by_erp_ids?erpId=id1&amp;erpId=...</h4>
                                <hr>
                                <h4 class="method-objects-title">PARAMETERS</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">erpId</span>
                                            <span class="type">string</span>
                                            <span class="repeated">repeated</span>
                                        </h3>
                                        <p class="method-object-description">The unique Id in your product
                                            catalogue.</p>
                                    </li>
                                </ul>
                                <hr>
                                <h4 class="method-response-title">RESPONSE</h4>
                                <ul class="method-objects">
                                    <li class="method-object">
                                        <h3 class="method-object-label">
                                            <span class="name">products</span>
                                            <span class="type">array</span>
                                        </h3>
                                        <p class="method-object-description">A list of product objects, the same as the
                                            previous method.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- [END] DELETE Product by ERP -->
                </div>
            </div>
        </div>
    </div>
</div>
