import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Store} from '../../shared/models/store';
import {MapsAPILoader} from '@agm/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-store-overview',
    templateUrl: './store-overview.component.html',
    styleUrls: ['./store-overview.component.css']
})
export class StoreOverviewComponent implements OnInit {

    faSearch = faSearch;

    searchControl = new FormControl();

    @Input()
    stores: Store[];

    @Input()
    selectedStore: Store;

    @Input()
    currentOffset: number;

    @Input()
    totalStores: number;

    @Input()
    pageSize: number = 25;

    @Input()
    pageLoading: boolean;

    minOffset: number = 0;

    @ViewChild('searchInput', {static: false})
    searchInput;

    @ViewChild('indicator', {static: false})
    indicator;

    @Output()
    searchedCenter: EventEmitter<google.maps.LatLng> = new EventEmitter<google.maps.LatLng>();

    @Output()
    onSelectStore: EventEmitter<Store> = new EventEmitter<Store>();

    @Output()
    newStore: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    pageSelected: EventEmitter<number> = new EventEmitter<number>();

    constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {

    }

    ngOnInit() {
        this.mapsAPILoader.load().then(() => {
            console.log('DEBUG', this.searchInput.nativeElement);
            const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, {
                types: []
            });
            autocomplete.setComponentRestrictions(
                {'country': ['au', 'nz']}
            );
            autocomplete.addListener('place_changed', () => this.executeAutocomplete(autocomplete));
        })
    }


    executeAutocomplete(autocomplete: google.maps.places.Autocomplete) {
        this.ngZone.run(() => {
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                this.searchedCenter.emit(place.geometry.location);
            }
        )
    }

    onNewStore() {
        this.newStore.emit(this.searchInput.nativeElement.value);
    }
}
