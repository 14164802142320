<div class="container-fluid">
    <div class="row no-gutters position-relative">
        <div class="d-none d-sm-flex col-sm-4 admin-overview">
            <admin-overview (onNewUser)="onNewUser()" class="w-100 mr-2"></admin-overview>
        </div>
        <div class="col-12 col-sm-8 position-relative">
            <div class="row justify-content-between mt-5">
                <div class="col-12 d-flex justify-content-between">
                    <div class="w-100 d-inline-flex input-group product-search">
                        <input [formControl]="searchControl" class="form-control border-right-0" placeholder="Search users by full name, vendor or email"
                               title="Search"/>
                        <div class="input-group-append">
                            <div class="input-group-text text-primary bg-white border-left-0">
                                <fa-icon [icon]="faSearch"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.customers.length > 0" class="row mt-3">
                <div class="col-12">
                    <app-page-indicator [minOffset]="minOffset"
                                        [currentOffset]="currentOffset"
                                        [numResults]="numResults"
                                        [maxIndicators]="isXSScreen()? 3 : 5"
                                        [pageSize]="pageSize"
                                        (pageSelected)="pageSelected.next($event)"
                                        (minOffsetChanged)="minOffset = $event">
                    </app-page-indicator>
                </div>
            </div>
            <div *ngIf="!isLoadingPage && customers.length > 0" class="row">
                <div class="col-12 h-100 mt-2 user-list">
                    <ul>
                        <li class="user" *ngFor="let user of customers">
                            <user-preview
                                [user]="user"
                                (onSelect)="onSelectUser($event)"></user-preview>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="!isLoadingPage && customers.length == 0" class="centered-container">
                <div class="content">
                    <div class="row mx-auto text-center justify-content-center">
                        <div class="col-8 no-results-found">
                        <fa-icon [icon]="faUserSlash" size="4x"></fa-icon>
                        <div class="message mx-auto mt-1">
                            We could not find what you are looking for. Please refine your search terms.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isLoadingPage" class="centered-container">
                <div class="content">
                    <app-loader [stroke]="'var(--charcoal-grey-a50)'"></app-loader>
                </div>
            </div>
        </div>
    </div>
</div>
