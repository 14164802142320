<div class="modal-header">
    <h2>Delete Store</h2>
    <button type="button" class="close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="error-message mb-2" *ngIf="errorMessage">{{errorMessage}}</div>
    <p><strong>Are you sure you want to delete this store?</strong></p>
    <div class="mt-3">
        <label>Address</label>
        <div class="address">{{store.address}}</div>
    </div>
    <p class="mt-3">
        <small>
            All information associated with this store will will be permanently deleted.<br>
            This may affect your purchases reporting based on location.
            If this is not what you want, please leave the store inactive.
            <span class="text-danger">This operation can not be undone.</span>
        </small>
    </p>
</div>
<div class="modal-footer">
    <div *ngIf="loading" class="w-100 d-flex align-items-center justify-content-end">
        <app-loader style="width: 38px; height: 100%;"
                    class="d-flex mr-2" width="38px" height="38px" stroke="var(--charcoal-grey-a50)">
        </app-loader>
    </div>
    <button type="button" [disabled]="loading" class="btn btn-outline-secondary" (click)="activeModal.dismiss('cancel click')">Cancel</button>
    <button type="button" [disabled]="loading || !!errorMessage" (click)="onDeleteStore()" class="btn btn-danger" ngbAutofocus>Ok</button>
</div>
