<form #userForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <div>
            <h2>{{isNewUserMode ? 'New User' : 'Edit User'}}</h2>
            <div *ngIf="isLoading">
                <div class="loader">
                    <div class="content">
                        <app-loader [stroke]="'var(--ash-grey)'"></app-loader>
                    </div>
                </div>
                <span class="small" *ngIf="isNewUserMode"> Creating a new customer. This may take a couple of seconds...</span>
                <span class="small" *ngIf="!isNewUserMode"> Updating {{this.form.get('name').value}}. This may take a couple of seconds...</span>
            </div>
            <div *ngIf="errorMessage != null" class="invalid-input">
                {{errorMessage}}
            </div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="mx-auto">
                    <label for="name">Name</label>
                    <input id="name" name="name" class="form-control" type="text" formControlName="name"
                           [disabled]="isLoading">
                    <div
                        *ngIf="this.form.get('name').invalid && (this.form.get('name').dirty || this.form.get('name').touched)">
                        <div *ngIf="this.form.get('name').errors?.required" class="invalid-input">
                            A name is required.
                        </div>
                    </div>
                </div>
                <div class="mx-auto">
                    <label for="email">Email</label>
                    <input id="email" name="email" class="form-control" type="text" formControlName="email"
                           [disabled]="isLoading">
                    <div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
                        <div *ngIf="emailControl.errors?.required" class="invalid-input">
                            An email is required.
                        </div>
                        <div *ngIf="emailControl.errors?.email" class="invalid-input">
                            Invalid email format. Please check the format and try again.
                        </div>
                    </div>
                </div>
                <div class="mx-auto">
                    <label for="vendor">Vendor</label>
                    <input id="vendor" name="vendor" class="form-control" type="text" formControlName="vendor"
                           [disabled]="isLoading">
                    <div
                        *ngIf="this.form.get('vendor').invalid && (this.form.get('vendor').dirty || this.form.get('vendor').touched)">
                        <div *ngIf="this.form.get('vendor').errors?.required" class="invalid-input">
                            A vendor name is required.
                        </div>
                    </div>
                </div>
                <div class="mx-auto">
                    <label for="credit" *ngIf="isNewUserMode">Credit Balance</label>
                    <label for="credit" *ngIf="!isNewUserMode">Add credit:</label>
                    <span *ngIf="!isNewUserMode" class="small">
                     balance {{currentBalance | currency:'AUD':'':'1.2'}}
                    </span>
                    <span *ngIf="!isNewUserMode" class="small">
                        + {{addCreditValue | currency:'AUD':'':'1.2'}}
                        = {{currentBalance + addCreditValue | currency:'AUD':'':'1.2'}}
                    </span>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">AUD</div>
                        </div>
                        <input id="credit" name="credit" class="form-control" type="text"
                               [min]="0.00" [step]="0.01" formControlName="credit"
                               [disabled]="isLoading">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="isLoading">
            Save
        </button>
    </div>
</form>
