<div class="row px-2 py-3 store-controls">
    <div class="col-12">
        <h5>{{store.vendor}}</h5>
        <h6 class="short-address">{{store | shortAddress}}</h6>
        <p>{{store.address}}</p>
        <div class="purchase-summary mt-3">
            <div *ngIf="store.amountToday == null">
                Loading purchase summary...</div>
            <div *ngIf="store.purchasesToday != null">{{store.purchasesToday}} transactions today</div>
            <div class="mt-1" *ngIf="store.amountToday != null">{{store.amountToday | currency}} spent today</div>
        </div>
        <div class="mt-3">
            <div *ngIf="loading" class="loader d-flex flex-row w-100 align-items-center">
                <app-loader style="width: 30px; height: 100%;"
                            class="d-flex" width="30px" height="30px" stroke="var(--charcoal-grey-a50)">
                </app-loader>
                <span class="d-flex ml-2"> {{store.active ? 'Deactivating store...' : 'Reactivating store...'}}</span>
            </div>
            <div *ngIf="!loading" [ngClass]="{'active': store.active, 'inactive': !store.active}">
                {{store.active ? 'Active' : 'Inactive'}}
            </div>
            <input type="text" autofocus="autofocus" style="display:none" />
            <button *ngIf="!loading && store.active" (click)="markActivity(false)" class="btn btn-transparent danger">Mark as inactive</button>
            <button *ngIf="!loading && !store.active" (click)="markActivity(true)" class="btn btn-transparent active">Reactivate</button>
        </div>
        <div class="mt-3">
            <button *ngIf="store.active" (click)="openPurchaseStream()"  [disabled]="loading" class="btn btn-primary stream w-100">Watch purchase stream</button>
            <button *ngIf="!store.active" (click)="openDeleteModal()" [disabled]="loading" class="btn btn-danger stream w-100">Delete store</button>
        </div>
    </div>
</div>
