import {Component, Input, OnInit} from "@angular/core";
import {UserLoadRequest} from "../dashboard";
import {FormControl, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CardsService} from "../cards.service";

const APPROVED_STATUS = 'Approved'
const REJECTED_STATUS = 'Rejected'

@Component({
    selector: 'request-modal',
    templateUrl: './request-modal.component.html',
    styleUrls: ['./request-modal.component.css']
})
export class RequestModalComponent implements OnInit {

    @Input()
    request: UserLoadRequest

    isLoading: boolean = false;

    amountControl = new FormControl(0.0, [Validators.required, Validators.min(0.01)])

    constructor(public activeModal: NgbActiveModal, private cardsService: CardsService) {}

    ngOnInit(): void {
        console.log(this.request)
        if (this.request != null) {
            this.amountControl.setValue(this.request.request.amount)
            if (this.requestStatus !== 'Pending' || this.paidByCard) {
                this.amountControl.disable()
            }
        }
    }

    get cardId() {
        return this.request.request.cardId;
    }

    get accountName() {
        return this.request?.account?.name || 'Deleted User'
    }

    get accountEmail() {
        return this.request?.account?.email || 'Unknown email';
    }

    get credit() {
        return this.request?.account?.credit;
    }

    get vendorName() {
        return this.request.request.vendor
    }

    get paidByCard() {
        return this.request.request.paid
    }

    get suburb() {
        return this.request.request?.store?.suburb || 'Unknown'
    }

    get requestAmount() {
        return this.amountControl.value;
    }

    get requestStatus() {
        return this.request.request.status
    }

    get requestTimestamp() {
        return new Date(Date.parse(this.request.request.timestamp)).toLocaleString()
    }

    get customerName() {
        return this.request.request?.name
    }

    get customerPhone() {
        return this.request.request?.phone
    }

    get customerNotes() {
        return this.request.request?.notes
    }

    get customerEmail() {
        return this.request.request?.email
    }

    get joinMailingList() {
        return this.request.request?.joinMailing || false
    }

    onApprove() {
        this.isLoading = true;
        this.cardsService.updateRequest(this.cardId, this.requestAmount, APPROVED_STATUS)
            .subscribe(
                updatedRequest => {
                    this.isLoading = false;
                    console.log('Approved', updatedRequest)
                    this.activeModal.close()
                },
                error => {
                    console.log(error)
                    this.isLoading = false
                })
    }

    onReject() {
        this.isLoading = true;
        this.cardsService.updateRequest(this.cardId, this.requestAmount, REJECTED_STATUS)
            .subscribe(
                updatedRequest => {
                    this.isLoading = false;
                    console.log('Rejected', updatedRequest)
                    this.activeModal.close()
                },
                error => {
                    console.log(error)
                    this.isLoading = false
                }
            )
    }
}
