<div (click)="onSelect.emit(product)" (dblclick)="onView.emit(product)" class="d-flex w-100 product-container" [ngClass]="{'selected': isSelected}">
    <div class="d-inline-flex">
        <img [src]="product.photoUrl" class="d-inline-flex align-self-center" width="auto" height="50px"/>
        <div class="align-self-center name">
            {{product.name}}
        </div>
    </div>
    <div class="d-inline-flex ml-auto">
        <div class="align-self-center p-2 category-container">
            <span *ngFor="let category of categories" class="badge badge-primary category">{{category}}</span>
        </div>
        <div class="align-self-center price">
            {{product.price | currency}}
        </div>
    </div>
</div>
