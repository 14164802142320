<div class="modal-header p-3">
    <h2>Loads Report</h2>
    <div class="text-header">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body p-3">
    <div *ngIf="!isLoading" class="row">
        <form [formGroup]="checkboxGroup" class="col-6 col-sm-8">
            <div class="form-check-inline align-middle">
                <input formControlName="pending" id="pending-report" type="checkbox" class="form-check-input">
                <label for="pending-report" class="form-check-label">Pending</label>
            </div>
            <div class="ml-1 form-check-inline align-middle">
                <input formControlName="approved" id="approved-report" type="checkbox" class="form-check-input">
                <label for="approved-report" class="form-check-label">Approved</label>
            </div>
            <div class="ml-1 form-check-inline align-middle">
                <input formControlName="rejected" id="rejected-report" type="checkbox" class="form-check-input">
                <label for="rejected-report" class="form-check-label">Rejected</label>
            </div>
        </form>
    </div>
    <div *ngIf="!isLoading" class="row">
        <div class="col-12">
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)"
                            class="mt-2"
                            [displayMonths]="2"
                            [dayTemplate]="t"
                            outsideDays="hidden">
            </ngb-datepicker>
            <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
            </ng-template>
        </div>
    </div>
    <div *ngIf="isLoading" class="row" style="min-height: 300px">
        <div class="col-12">
            <div class="loading">
                <div class="position-relative m-auto">
                    <div class="container">
                        <app-loader [width]="'75px'" [height]="'75px'"></app-loader>
                    </div>
                    <div>Downloading report...</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="!toDate && !fromDate"
            (click)="generateReport()"
            class="btn btn-primary mt-4 w-100">
        Generate report
    </button>
</div>
