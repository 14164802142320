<div class="overview">
    <div class="row">
        <div class="col-12">
            <h2>User Admin</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="description">
                Manage vendor users and add credit to each user.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="d-flex btn-transparent" (click)="onNewUser.emit();">New user</button>
        </div>
    </div>
</div>
