<!-- [START] Page indicator -->
<div *ngIf="numResults > 0" class="row justify-content-start">
    <div class="col-12 d-flex text-center">
        <button [disabled]="currentPage == 1" class="indicator left" (click)="currentPage = currentPage - 1" >
            <fa-icon [icon]="faChevronLeft" class="w-100 value"></fa-icon>
        </button>

        <button *ngFor="let page of pages" class="indicator page-indicator"
                (click)="currentPage = page" [ngClass]="{'current-page': currentPage == page}">
            <span class="w-100 value">{{page}}</span>
        </button>

        <div *ngIf="isNotNearLastPage()" class="indicator ellipsis">
            <span class="mx-auto value">...</span>
        </div>
        <button *ngIf="isNotLastPage()" (click)="currentPage = lastPage" class="indicator page-indicator">
            <span class="w-100 value">{{lastPage}}</span></button>

        <button class="indicator right" [disabled]="lastPage == currentPage" (click)="currentPage = currentPage + 1" >
            <fa-icon [icon]="faChevronRight" class="w-100 value"></fa-icon>
        </button>

        <div class="d-inline-flex ml-3 align-items-center">
            <div class="d-flex search-results">
                <span class="current-results">{{currentOffset + 1}}-{{getLastPageResult()}}</span> <span>&nbsp;of {{numResultsText}}</span>
            </div>
        </div>
    </div>
</div>
<!-- [END] Page indicator -->
