import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomerAccount} from "../../shared/models/admin";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {catchError, debounceTime, map} from "rxjs/operators";
import {Subscription} from "rxjs";
import {CurrencyPipe} from "@angular/common";
import {CustomerService} from "../customer.service";
import {HttpErrorResponse} from "@angular/common/http";
import {
    INTERNAL_ERROR_MSG,
    INTERNAL_SERVER_ERROR,
    SERVICE_UNAVAILABLE,
    SERVICE_UNAVAILABLE_MSG
} from "../../product/product-utils";

@Component({
    selector: 'user-form',
    templateUrl: 'user-form.component.html',
    styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit, OnDestroy {

    isLoading: boolean = false;
    creditFormSub: Subscription;
    errorMessage: string = null;

    @Input()
    customer: CustomerAccount
    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        vendor: new FormControl('', [Validators.required]),
        credit: new FormControl('0.00', [Validators.required])
    });

    constructor(public activeModal: NgbActiveModal,
                private currency: CurrencyPipe,
                private customerService: CustomerService) {
    }

    ngOnInit(): void {
        if (this.customer != null) {
            this.form.setValue({
                name: this.customer.name,
                email: this.customer.email,
                vendor: this.customer.vendor,
                credit: '0.00'
            })
        }
        const creditControl = this.form.get('credit')
        this.creditFormSub = creditControl.valueChanges.pipe(
            debounceTime(500),
            map(value => this.parseCurrencyValue(value))
        ).subscribe(value => {
            const currencyFmt = this.currency.transform(value, 'AUD', '', '1.2');
            creditControl.setValue(currencyFmt, { emitEvent: false });
        })
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.creditFormSub.unsubscribe();
    }

    get isNewUserMode() {
        return this.customer == null;
    }

    get currentBalance() {
        return this.customer != null ? this.customer.credit || 0.0 : 0.0;
    }

    get emailControl() {
        return this.form.get('email');
    }
    get addCreditValue() {
        return this.parseCurrencyValue(this.form.get('credit').value);
    }

    parseCurrencyValue(value: string) {
        return value != null
            ? parseFloat(value.toString().replace(/[A-Za-z!@#$%^&*(),]/g, ''))
            : 0.0;
    }

    onSubmit() {
        this.isLoading = true;
        this.errorMessage = null;
        const formValue = this.form.value;
        const data: CustomerAccount = {
            name: formValue.name,
            vendor: formValue.vendor,
            email: formValue.email,
            credit: this.parseCurrencyValue(formValue.credit)
        }

        const call = this.isNewUserMode
            ? this.customerService.createCustomer(data)
            : this.customerService.updateCustomer(this.customer.id, data)

        call.subscribe(
            account => this.activeModal.close(account),
                error => this.errorMessage = this.getHttpErrorMessage(error)
        )
    }

    private getHttpErrorMessage(error: HttpErrorResponse) {
        this.isLoading = false;
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }
}
