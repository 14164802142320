<div *ngIf="user" (click)="onSelect.emit(user)" class="user w-100">
    <div class="row">
        <div class="col-6 col-sm-4 info d-flex flex-column align-items-start justify-content-start">
            <div>{{user.name}}</div>
            <div class="mt-1">{{user.credit || 0 | currency}}</div>
        </div>
        <div class="col-6 col-sm-4 info d-flex align-items-center justify-content-center">
            <span class="my-auto">{{user.vendor}}</span>
        </div>
        <div class="d-none d-sm-flex col-sm-4 info align-items-center justify-content-end">
            {{user.email}}
        </div>
    </div>
</div>
