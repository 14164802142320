import {Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {CustomerAccount, CustomerSearchResponse} from "../../shared/models/admin";
import {CustomerService} from "../customer.service";
import {Observable, of, merge, Subject, Subscription} from "rxjs";
import {catchError, debounceTime, switchMap, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {
    INTERNAL_ERROR_MSG,
    INTERNAL_SERVER_ERROR,
    SERVICE_UNAVAILABLE,
    SERVICE_UNAVAILABLE_MSG
} from "../../product/product-utils";
import {faUserSlash, faSearch} from "@fortawesome/free-solid-svg-icons";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl} from "@angular/forms";
import {UserFormComponent} from "../user-form/user-form.component";
/**
 * Created by Karl on 1/9/17.
 */

const XS_SCREEN_WIDTH = 400;


@Component({
    selector: `admin-dashboard`,
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

    faUserSlash = faUserSlash;
    faSearch = faSearch;

    isLoadingPage: boolean = true;
    errorMessage: string = null;
    customers: CustomerAccount[] = [];

    // Search fields
    pageSelected: Subject<number> = new Subject<number>();
    searchControl = new FormControl('')
    minOffset: number = 0;
    currentOffset: number = 0;
    numResults: number = this.customers?.length;
    pageSize: number = 10;
    userChanged: EventEmitter<CustomerAccount> = new EventEmitter<CustomerAccount>();
    seachSub: Subscription;

    constructor(private customerService: CustomerService,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.isLoadingPage = true;
        const initialLoad$ = this.searchCustomers(this.searchControl.value, 0);

        const searchInput$ = this.searchControl.valueChanges.pipe(
            debounceTime(150),
            tap(_ => this.resetSearchState()),
            switchMap(searchText => this.searchCustomers(searchText, 0))
        )

        const pageChange$ = this.pageSelected.pipe(
            tap(_ => this.resetSearchState()),
            switchMap(page => this.searchCustomers(this.searchControl.value, this.toOffset(page)))
        )

        const reload$ = this.customerService.onUpdateSuccess.pipe(
            tap(_ => this.resetSearchState()),
            switchMap(acct => this.searchCustomers(this.searchControl.value, this.currentOffset))
        )
        this.seachSub = merge(initialLoad$, searchInput$, pageChange$, reload$).pipe(
            tap(_ => this.isLoadingPage = false)
        ).subscribe(
            response => this.updateCustomersPage(response),
            error => console.log('Error', error)
        )
    }

    ngOnDestroy(): void {
        this.seachSub.unsubscribe();
    }

    private handleError(error): Observable<CustomerSearchResponse> {
        this.isLoadingPage = false;
        if (error instanceof HttpErrorResponse) {
            this.errorMessage = this.getHttpErrorMessage(error);
        } else {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
        }

        return of(null);

    }

    private getHttpErrorMessage(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }

    onNewUser() {
        this.modalService.open(UserFormComponent, {backdrop: 'static', centered: true});
    }

    onSelectUser(user: CustomerAccount) {
        const activeModal = this.modalService.open(UserFormComponent, {backdrop: 'static', centered: true})
        activeModal.componentInstance.customer = user;
    }

    isXSScreen() {
        return window.innerWidth < XS_SCREEN_WIDTH;
    }

    private resetSearchState() {
        this.isLoadingPage = true;
        this.customers = [];
        this.minOffset = 0;
        this.numResults = 0;
        this.errorMessage = null;
    }

    private toOffset(page: number) {
        return Math.max(0, (page - 1) * this.pageSize);
    }

    private searchCustomers(searchText: string, offset: number): Observable<CustomerSearchResponse> {
        return this.customerService.searchCustomers(searchText, offset).pipe(
            catchError(error => this.handleError(error))
        )
    }

    private updateCustomersPage(response: CustomerSearchResponse) {
        if (response == null) {
            return;
        }
        this.currentOffset = response.currentOffset;
        this.customers = response.customers || [];
        this.numResults = response.numResults;
        this.pageSize = response.pageSize;
    }
}
