import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '../index';
import {StoreService, watchPurchaseStream} from '../store.service';
import {tap, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {fadeInOut} from '../animations';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteStoreComponent} from '../delete-store/delete-store.component';

@Component({
    selector: 'app-store-controls',
    templateUrl: './store-controls.component.html',
    styleUrls: ['./store-controls.component.css'],
    animations: [fadeInOut]
})
export class StoreControlsComponent implements OnInit {

    loading: boolean = false;

    @Output()
    storeDeleted: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    store: Store;

    constructor(private storeService: StoreService, private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    markActivity(active: boolean) {
        this.loading = true
        this.storeService.markStoreActivity(this.store.websafeKey, active).pipe(
            catchError( error => {
                return of(null)
            }),
            tap(_ => this.loading = false),
            tap( result => console.log('ACTIVITY', result))
        ).subscribe(store => this.onMarkedActivity(store) )
    }

    onMarkedActivity(store: Store | null) {
        if (store == null) {
            return;
        }
        this.store.active = store.active;
    }

    openPurchaseStream() {
        watchPurchaseStream(this.store);
    }

    openDeleteModal() {
        const modalRef = this.modalService.open(DeleteStoreComponent, {backdrop: 'static', keyboard: false});
        modalRef.componentInstance.store = this.store;
        modalRef.result.then(
            result => {
                if (result == null) {
                    return;
                }

                if ( result == DeleteStoreComponent.DELETED_STORE ) {
                    this.storeDeleted.emit();
                }
            }
        )
    }
}
