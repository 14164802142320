<div *ngIf="product" class="product-preview">
    <div class="row justify-content-end">
        <div class="col-2">
            <button (click)="close.emit(null)" class="btn btn-transparent w-100 close">
                <fa-icon [icon]="faTimes" [size]="'sm'">

                </fa-icon>
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <div class="img-container">
                <img [src]="product.photoUrl" alt="" class="img-thumbnail mx-auto d-block">
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h3 class="name">{{product.name}}</h3>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <div class="d-flex w-100 justify-content-between">
                <div class="d-inline-flex price">{{product.price | currency}}</div>
                <div class="d-inline-flex tax-rate">{{product.taxRate | percent}} GST</div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <div class="align-self-center category-container">
                <span *ngFor="let category of categories" class="badge badge-primary category">{{category}}</span>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <p class="description">{{product.description}}</p>
        </div>
    </div>
    <div class="row mt-3 barcode">
        <div class="col-6">
            <label>Barcode</label>
            <div class="value">{{product.barcode}}</div>
        </div>
        <div class="col-6">
            <div class="img-container  w-100">
                <img [src]="defaultBarcodeUrl" alt="">
            </div>
        </div>
    </div>
    <div class="row mt-3 barcode qrcode">
        <div class="col-6">
            <label>QR code</label>
        </div>
        <div class="col-6">
            <div class="img-container w-100 float-right">
                <img [src]="qrCode" alt="">
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <button (click)="viewProduct.emit(product.websafeKey)"
                    class="btn btn-sm btn-primary w-100">
                <fa-icon [icon]="faEye"></fa-icon><span> View product</span>
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <button (click)="editProduct.emit(product.websafeKey)"
                class="btn btn-sm btn-primary w-100">
                <fa-icon [icon]="faEdit"></fa-icon><span> Edit product</span>
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <button (click)="deleteProduct.emit(product.websafeKey)"
                    class="btn btn-sm btn-danger w-100">
                <fa-icon [icon]="faTrash"></fa-icon><span> Delete product</span>
            </button>
        </div>
    </div>

</div>
