/**
 * Created by Karl on 13/02/2017.
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from './admin/admin.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

    disabled: any = environment!.disabled;

    showCollapsed = false;

    currentUrl : string = '';
    isLoggedIn: boolean = false;
    isVendorSetup: boolean = false;

    isVendorSetupSub: Subscription;
    isLoggedInSub: Subscription;

    constructor(public router: Router,
                public adminService: AdminService) {
    }


    ngOnInit(): void {
        this.currentUrl = this.router.url;

        this.router.events.subscribe((nav: NavigationEnd) => this.currentUrl = this.router.url);

        this.isLoggedInSub = combineLatest(this.router.events, this.adminService.isLoggedIn$).pipe(
            tap(_ => this.currentUrl = this.router.url),
            map( ([event, isLoggedIn]) => [this.router.url, isLoggedIn])
        ).subscribe(
            ([currentUrl, isLoggedIn]) => this.isLoggedIn = isLoggedIn && currentUrl && !this.currentUrl.endsWith('/login')
        );

        this.isVendorSetupSub = this.adminService.isLoggedInAndSetup$.subscribe( isSetup => {
            this.isVendorSetup = isSetup;
        });
    }

    get showNav() {
        return this.currentUrl && !this.currentUrl.includes('/api')
    }

    logout() {
        this.adminService
            .logout()
            .subscribe(_ => this.router.navigate(['login']));
    }

    ngOnDestroy(): void {
        this.isLoggedInSub.unsubscribe();
        this.isVendorSetupSub.unsubscribe();
    }

}
