<div class="row w-100 tile align-items-center justify-content-between no-gutters" (click)="onDrillDown.next(request)">

    <div class="col-2 col-sm-2 name flex-column justify-content-center">
        <div class="d-flex">
            <small class="font-weight-bold">{{accountName}}</small>
        </div>
        <div *ngIf="credit != null" class="d-flex">
            <small [class.text-danger]="requestStatus == 'Pending' && !paidByCard && credit < requestAmount"> Credit: {{credit | currency}} </small>
        </div>
        <div *ngIf="credit == null" class="d-flex">
            N/A
        </div>
    </div>

    <div class="col-2 col-sm-2 flex-column">
        <div class="d-flex">
            <small class="font-weight-bold">{{vendorName}}</small>
        </div>
        <div class="d-flex">
            <small>{{suburb}} Store</small>
        </div>
    </div>

    <div class="col-2 col-sm-3 col-md-3 flex-column">
        <div class="d-flex text-left text-sm-center">
            <small class="font-weight-bold">{{cardId}}: {{requestAmount | currency}}</small>
            <small *ngIf="paidByCard"><small>(Paid by card)</small></small>
        </div>
        <div class="d-flex text-left">
            <small>{{requestTimestamp}}</small>
        </div>
        <div *ngIf="customerName" class="d-flex text-left">
            <small>{{customerName}}</small>
        </div>
        <div *ngIf="customerPhone" class="d-flex text-left">
            <small>{{customerPhone}}</small>
        </div>
        <div *ngIf="customerEmail" class="d-flex text-left">
            <small>{{customerEmail}} <span *ngIf="joinMailingList">(Joined)</span></small>
        </div>
    </div>
    <div class="col-2 col-sm-2 col status"
         [class.pending]="requestStatus == 'Pending'"
         [class.approved]="requestStatus == 'Approved'"
         [class.rejected]="requestStatus == 'Rejected'">
        {{requestStatus}}
    </div>

    <div class="col-2 col-sm-2 action text-center">
        <button *ngIf="requestStatus == 'Pending' && (paidByCard || credit >= requestAmount)"
                class="btn btn-sm btn-outline-success"
                (click)="emitApprove($event)">
            Approve
        </button>
        <button *ngIf="requestStatus == 'Pending' && !paidByCard && credit < requestAmount"
                class="btn btn-sm btn-outline-danger"
                (click)="emitReject($event)">
            Reject
        </button>
    </div>
</div>

