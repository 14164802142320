/**
 * Created by Karl on 6/9/17.
 */
import {faSmile} from "@fortawesome/free-solid-svg-icons";
import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {CardsService} from "./cards.service";
import {LoadStatuses, StatusLoadResponse, UserLoadRequest} from "./dashboard";
import {debounceTime, switchMap, tap} from "rxjs/operators";
import {merge, Subject, Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RequestModalComponent} from "./request-modal/request-modal.component";
import {ReportsModalComponent} from "./reports-modal/reports-modal.component";

// TODO Export as constant.
const XS_SCREEN_WIDTH = 400;
const APPROVED_STATUS = 'Approved'
const REJECTED_STATUS = 'Rejected'

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    faSmile = faSmile

    // Form controls
    checkboxGroup = new FormGroup({
        'pending': new FormControl(true),
        'approved': new FormControl(false),
        'rejected': new FormControl(false)
    })

    requests: UserLoadRequest[] = [];

    minOffset: number = 0;
    currentOffset: number = 0;
    numResults: number = this.requests?.length;
    pageSize: number = 10;
    pageSelected$: Subject<number> = new Subject<number>();
    currentPage = 1;
    isLoadingPage: boolean = false;

    dataSub: Subscription

    constructor(private cardsService: CardsService,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        const initialLoad$ = this.getLoadRequests(this.checkboxGroup.value, this.currentPage)

        const checkboxesChanged$ = this.checkboxGroup.valueChanges.pipe(
            debounceTime(100),
            tap(() => {
                this.currentPage = 1;
                this.currentOffset = 0;
                this.minOffset = 0;
                this.requests = [];
            }),
            switchMap(params => this.getLoadRequests(params, this.currentPage))
        )

        const pageChanged$ = this.pageSelected$.pipe(
            tap(pageNumber => {
                this.currentPage = pageNumber
                this.currentOffset = (pageNumber - 1) * this.pageSize
            }),
            switchMap(pageNumber => this.getLoadRequests(this.checkboxGroup.value, pageNumber))
        )

        this.dataSub = merge(initialLoad$, checkboxesChanged$, pageChanged$).pipe(
            tap(() => this.isLoadingPage))
            .subscribe(
                (response) => this.updateResults(response),
                error => console.log(error),
                () => this.isLoadingPage = false
            )

        this.cardsService.onRequestUpdated$.subscribe(updatedRequest => this.updatePage(updatedRequest))
    }

    private getLoadRequests(params: LoadStatuses, pageNumber: number) {
        this.isLoadingPage = true;
        return this.cardsService.getLoadRequests(params, pageNumber)
    }

    private updateResults(response: StatusLoadResponse) {
        this.isLoadingPage = false
        this.requests = response.requests || []
        this.numResults = response.count
    }

    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }

    isXSScreen() {
        return window.innerWidth < XS_SCREEN_WIDTH;
    }

    onApprove(request: UserLoadRequest) {
        const cardId = request.request.cardId
        const amount = request.request.amount
        this.cardsService.updateRequest(cardId, amount, APPROVED_STATUS)
            .subscribe(updatedRequest => console.log('Approved', updatedRequest))
    }

    onReject(request: UserLoadRequest) {
        const cardId = request.request.cardId
        const amount = request.request.amount
        this.cardsService.updateRequest(cardId, amount, REJECTED_STATUS)
            .subscribe(updatedRequest => console.log('Rejected', updatedRequest))
    }

    onDrillDown(request: UserLoadRequest) {
        const activeModal = this.modalService.open(RequestModalComponent, {backdrop: 'static', centered: true})
        activeModal.componentInstance.request = new UserLoadRequest(request);
    }

    openReportModal() {
        const activeModal = this.modalService.open(ReportsModalComponent, {backdrop: 'static', centered: true})
        activeModal.componentInstance.loadStatuses = Object.assign({}, this.checkboxGroup.value)

    }

    private updatePage(updatedRequest: UserLoadRequest) {
        this.requests.forEach(request => {
            if (request.account.id === updatedRequest.account.id) {
                request.account = updatedRequest.account
            }

            if (request.request.cardId === updatedRequest.request.cardId) {
                request.request = updatedRequest.request;
            }
        })
    }

}
