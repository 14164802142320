<form>
    <div class="modal-header">
        <h2>New Store</h2>
        <button type="button" class="close" aria-label="Close" [disabled]="loading" (click)="activeModal.close(!formMode)">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>
    <div class="modal-body row">
        <div class="col-12 w-100 h-100">
            <div *ngIf="formMode && !loading"  [@fadeInOut]>
                <div class="form-group">
                    <label>Enter a store address</label>
                    <div class="w-100 d-inline-flex input-group">
                        <input #searchInput class="form-control border-right-0" placeholder="Enter address"
                               (keydown)="onKeydown($event)"
                               autocorrect="off" autocapitalize="off" spellcheck="off"
                               title="Search" [formControl]="searchControl"/>
                        <div class="input-group-append">
                            <div class="input-group-text text-primary bg-white border-left-0">
                                <img height="20px" src="./assets/imgs/store-icon-selected.png">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="vendor-input">Enter the store vendor<br>
                        <small>Users with the same vendor tag will only be able to see these stores.</small>
                    </label>
                    <input id="vendor-input" class="form-control" title="Vendor" type="text" [formControl]="vendorControl">
                    <div *ngIf="vendorControl.invalid && (vendorControl.dirty || vendorControl.touched)">
                        <div *ngIf="vendorControl.errors?.required" class="invalid-input">
                            A vendor name is required.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Select if the store should be active. <br>
                        <small>Inactive stores do not appear in the app. You can activate it later.</small>
                    </label>
                    <select [(ngModel)]="storeActive" name="store-active" class="form-control">
                        <option [value]="true">Active</option>
                        <option [value]="false">Inactive</option>
                    </select>
                </div>
            </div>
            <div *ngIf="loading" [@fadeInOut]>
                <div class="loader">
                    <app-loader class="d-inline-flex" style="width: 35px; height: 100%;"
                                [width]="'35px'" [height]="'35px'" stroke="var(--charcoal-grey-a50)"></app-loader>
                    <span class="d-inline-flex ml-2">Creating store...</span>
                </div>
            </div>
            <div *ngIf="!formMode && !loading" [@fadeInOut]>
                <div class="pre">
                    <label>Great! Your {{store.suburb}} store has been created.</label>
                    <div class=" mt-3">
                        <label>Store</label>
                        <p>{{store.address}}.
                            <span [ngClass]="{'active': store.active, 'inactive': !store.active}">
                                {{store.active ? ' Active' : ' Inactive'}}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="onCreateStore()"
                *ngIf="formMode"
                [disabled]="!isValidStore"
                [@fadeInOut]
                class="btn btn-primary">
            Create
        </button>
        <button
            *ngIf="!formMode && !loading"
            class="btn btn-success"
            [@fadeInOut]
            [disabled]="!store"
            (click)="onGoToStore()">
            Go to store
        </button>
    </div>
</form>
