import {Component, EventEmitter, Output} from "@angular/core";


@Component({
    selector: `admin-overview`,
    templateUrl: './admin-overview.component.html',
    styleUrls: ['./admin-overview.component.css']
})
export class AdminOverviewComponent {

    @Output()
    onNewUser: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }
}
