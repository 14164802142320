/**
 * Created by Karl on 20/8/17.
 */
import {Component} from "@angular/core";
import {AdminService} from "../../admin.service";

@Component({
    selector: 'admin-invite-user',
    template: `
        <div class="row">
            <div *ngIf="!submitting" class="col-md-12">
                <div>
                    <h2>Invite User</h2>
                    <input type="email" [(ngModel)]="email"/>
                    <button class="btn btn-primary" (click)="inviteUser()">Invite User</button>
                    <p *ngIf="submitted">The user with {{email}} has successfully been invited.</p>
                </div>
             </div>
        </div>`

})
export class InviteUserComponent {

    email: string;
    submitting: boolean;
    submitted: boolean;

    constructor(private adminService: AdminService) {}

    inviteUser() {
        this.submitting = true;
        this.adminService.inviteUser(this.email)
            .subscribe(msg =>  {
                this.submitting = false;
                this.submitted = true;
            })
    }
}
