<div class="modal-header">
    <div class="p-3">
        <h3>
            <span [class.pending]="requestStatus === 'Pending'"
                  [class.approved]="requestStatus === 'Approved'"
                  [class.rejected]="requestStatus === 'Rejected'">
                    {{requestStatus}} {{cardId}}
                </span>
            <small *ngIf="paidByCard"> - Paid</small>
        </h3>
        <div class="mt-2 text-header">{{accountName}}</div>
        <div class="text-header">{{accountEmail}}</div>
        <div class="mt-2 text-header ">
            <strong>{{vendorName}}:</strong> {{suburb}} Store
        </div>
        <div class="text-header">
            {{requestTimestamp}}
        </div>
        <div *ngIf="!!customerName || !!customerPhone || !!customerEmail" class="mt-2">
            <strong>Customer Info</strong>
        </div>
        <div *ngIf="customerName" class="text-header">
            {{customerName}}
        </div>
        <div *ngIf="customerPhone" class="text-header">
            {{customerPhone}}
        </div>
        <div *ngIf="customerEmail" class="text-header">
            {{customerEmail}} <span *ngIf="joinMailingList">(Joined)</span>
        </div>

    </div>
    <div class="p-3">
        <div class="text-header clearfix">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="mt-3 text-header">
            {{requestTimestamp}}
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="row px-3 pt-3">
        <div class="col-12">
            <label>Current credit: <span [class.rejected]="requestStatus != 'Pending' || !paidByCard && credit < requestAmount">{{credit | currency}}</span></label>
        </div>
    </div>
    <div class="row px-3 pb-3">
        <div class="col-12">
            <div>
                <label for="notes">Notes:</label>
                <p id="notes">{{customerNotes}}</p>
            </div>
            <div>
                <label for="amount">Amount:</label>
                <input id="amount" name="amount" class="form-control" type="number"
                       [min]="0.00" [step]="0.01" [formControl]="amountControl">
                <div *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched)">
                    <div *ngIf="amountControl.errors?.required" class="invalid-input">
                        An amount is required.
                    </div>
                    <div *ngIf="amountControl.errors?.min" class="invalid-input">
                        The minimum amount is $0.00
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button [disabled]="requestStatus !== 'Pending' || paidByCard || requestAmount <= 0.0 || isLoading"
            class="btn btn-outline-danger"
            (click)="onReject()">Reject</button>
    <button [disabled]="requestStatus !== 'Pending' || !paidByCard && credit < requestAmount ||  requestAmount <= 0.0 || isLoading"
            class="btn btn-outline-success"
            (click)="onApprove()">Approve</button>
</div>
