import {Store} from "../store";

export class LoadStatuses {
    pending: boolean
    approved: boolean
    rejected: boolean
}

export class UserAccount {
    id: string
    email: string
    vendor: string
    name: string
    credit: number

    constructor(user: UserAccount) {
        this.id = user.id;
        this.email = user.email;
        this.vendor = user.vendor;
        this.name = user.name;
        this.credit = user.credit;
    }
}

export class LoadRequest {
    id: string
    cardId: string
    userId: string
    vendor: string
    amount: number
    status: string
    store: Store
    timestamp: string
    name?: string
    phone?: string
    notes?: string
    email?: string
    joinMailing?: boolean
    paid?: boolean

    constructor(loadRequest: LoadRequest) {
        this.id = loadRequest.id;
        this.cardId = loadRequest.cardId;
        this.userId = loadRequest.userId;
        this.vendor = loadRequest.vendor;
        this.amount = loadRequest.amount;
        this.status = loadRequest.status;
        this.store = Object.assign({}, loadRequest.store)
        this.timestamp = loadRequest.timestamp;
        this.name = loadRequest?.name;
        this.phone = loadRequest?.phone;
        this.notes = loadRequest?.notes;
        this.email = loadRequest?.email;
        this.joinMailing = loadRequest?.joinMailing;
        this.paid = loadRequest?.paid
    }
}

export class UserLoadRequest {
    account: UserAccount
    request: LoadRequest

    constructor(loadRequest: UserLoadRequest) {
        this.account = loadRequest.account != null ? new UserAccount(loadRequest.account) : null;
        this.request = new LoadRequest(loadRequest.request);
    }
}


export class StatusLoadResponse {
    requests: UserLoadRequest[]
    count: number
    nextPage: string
}
