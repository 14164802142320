import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdminService} from './admin/admin.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {first, switchMap, map} from 'rxjs/operators';
import {buildUrl, getAuthHeaders} from './utils';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import {LoadStatuses} from "./dashboard/dashboard";

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    constructor(private adminService: AdminService, private http: HttpClient) {

    }

    generateLoadsReport(loadStatuses: LoadStatuses, fromDate: NgbDate, toDate: NgbDate) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.generateLoadsReportOnServer(token, loadStatuses, fromDate, toDate)),
            first()
        );
    }

    generateReport(fromDate: NgbDate, toDate: NgbDate) : Observable<any> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.generateReportOnServer(token, fromDate, toDate)),
            first()
        );
    }

    private toDateMark(date: NgbDate, isStart: boolean) : Date {
        return new Date(
            date.year,
            date.month - 1,
            date.day,
            isStart ? 0 : 23,
            isStart ? 0 : 59,
            isStart? 0 : 59,
            isStart ? 0 : 999
        )
    }

    private toISOString(date: Date) : string {
        const tzo = -date.getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';
        const pad = function (num) {
            return `${num}`.padStart(2, '0');
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
    }

    private generateReportOnServer(token: string, fromDate: NgbDate, toDate: NgbDate | null): Observable<any> {
        const url = buildUrl(environment.reportingUrl, ['orders', 'generate_report'], {})
        const startTime = this.toDateMark(fromDate, true);
        const endTime = toDate == null
            ? this.toDateMark(fromDate, false)
            : this.toDateMark(toDate, false);
        const params = {
            'startTime': this.toISOString(startTime),
            'endTime': this.toISOString(endTime)
        };
        return this.http.post(url, params, {
                observe: 'response',
                responseType: 'arraybuffer',
                headers: getAuthHeaders(token)
            }
         ).pipe(
             map( response => ({
                 'contentType': response.headers.get('Content-Type'),
                 'content': response.body
             }))
        );
    }

    private generateLoadsReportOnServer(token: string, loadStatuses: LoadStatuses, fromDate: NgbDate, toDate: NgbDate) {
        const url = buildUrl(environment.reportingUrl, ['cards', 'loads_report'], {})
        const startTime = this.toDateMark(fromDate, true);
        const endTime = toDate == null
            ? this.toDateMark(fromDate, false)
            : this.toDateMark(toDate, false);
        const params = {
            'pending': loadStatuses.pending,
            'approved': loadStatuses.approved,
            'rejected': loadStatuses.rejected,
            'startTime': this.toISOString(startTime),
            'endTime': this.toISOString(endTime)
        };
        return this.http.post(url, params, {
                observe: 'response',
                responseType: 'arraybuffer',
                headers: getAuthHeaders(token)
            }
        ).pipe(
            map( response => ({
                'contentType': response.headers.get('Content-Type'),
                'content': response.body
            }))
        );
    }
}
