/**
 * Created by Karl on 30/8/17.
 */
import {Component} from "@angular/core";
import {Router} from "@angular/router";
@Component({
    selector: 'welcome-user',
    template: `
        <div class="container centered-block justify-content-center">
            <div class="col-md-10 col-centered">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <h2>Welcome to Why Leave Town</h2>
                        <p>Your account has been created.</p>
                        <button class="btn btn-primary" (click)="next()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: ['.centered-block { height: 100%; display: flex; padding-bottom: 5%; }', '.fill {width: 100%}']
})
export class WelcomeUserComponent {

    constructor(private router: Router) {

    }

    next() {
        this.router.navigate(['/products']);
    }
}
