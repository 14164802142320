import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {of, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {StoreService, watchPurchaseStream} from '../store.service';
import {catchError, tap} from 'rxjs/operators';
import {PurchaseSummary, Store} from '../index';
import {Subscription} from 'rxjs';

const POLL_TIMER = 1000 * 60 * 5;

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit, OnDestroy {

    @Input()
    store: Store;

    @Input()
    selected: boolean;

    loadedSummary: boolean = false;

    summarySub: Subscription;

    constructor(private storeService: StoreService) {
    }

    ngOnInit() {
        this.summarySub = timer(100, POLL_TIMER).pipe(
            switchMap( _ => this.storeService.getPurchaseSummary(this.store).pipe(
                catchError( error => of(null) ),
            )),
            tap( _ => this.loadedSummary = true)
        ).subscribe( summary => this.onPurchaseSummary(summary))
    }

    private onPurchaseSummary(summary: PurchaseSummary | null) {
        if(summary == null) {
            return;
        }

        this.store.purchasesToday = summary.numPurchases;
        this.store.amountToday = summary.total;
    }

    ngOnDestroy() {
        this.summarySub.unsubscribe()
    }

    get purchaseSummaryStr() {
        if (!this.loadedSummary) {
            return 'Loading today\'s purchase summary...';
        }

        if (this.loadedSummary && (this.store.purchasesToday == null || this.store.purchasesToday === undefined)) {
            return 'Unable to retrieve today\'s purchase summary';
        }

        if (this.store.purchasesToday == 0) {
            return 'No purchases today';
        }

        return `${this.store.purchasesToday} transactions processed today valued $${this.store.amountToday.toFixed(2)}`
    }

    openPurchaseStream() {
        watchPurchaseStream(this.store);
    }
}
