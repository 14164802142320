<div class="container">
    <div class="row">
        <div class="d-none d-sm-flex col-sm-4" style="min-height: 100vh">
            <app-products-overview *ngIf="!selectedProduct" class="w-100"></app-products-overview>
            <app-product-preview *ngIf="selectedProduct" [product]="selectedProduct"
                                 (viewProduct)="viewProduct($event)"
                                 (editProduct)="editProduct($event)"
                                 (deleteProduct)="deleteProduct($event)"
                                 (close)="selectedProduct = null" class="w-100"></app-product-preview>
        </div>
        <div class="col-12 col-sm-8 p-1" style="min-height: 100vh">
            <!-- [START] Search bar and form productFormData button -->
            <div class="row justify-content-between mt-5">
                <div class="col-12 d-flex justify-content-between">
                    <div class="w-75 d-inline-flex input-group product-search">
                        <input #searchInput class="form-control border-right-0" placeholder="Search products"
                               title="Search"/>
                        <span class="input-group-append">
                            <div class="input-group-text text-primary bg-white border-left-0">
                                <fa-icon [icon]="faSearch"></fa-icon>
                            </div>
                        </span>
                    </div>
                    <div class="d-inline-flex">
                        <button (click)="addProduct()" class="d-block btn btn-primary">
                            <fa-icon [icon]="faPlus"></fa-icon>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <!-- [END] Search bar and form productFormData button -->

            <!-- [START] Top page indicator -->
            <div *ngIf="numResults > 0" class="row mt-3">
                <div class="col-12">
                    <app-page-indicator [minOffset]="minOffset"
                                        [currentOffset]="currentOffset"
                                        [numResults]="numResults"
                                        [maxIndicators]="isXSScreen()? 3 : 5"
                                        [pageSize]="pageSize"
                                        (pageSelected)="pageSelected.next($event)"
                                        (minOffsetChanged)="minOffset = $event">
                    </app-page-indicator>
                </div>
            </div>
            <!-- [END] Top page indicator -->

            <!-- [START] Page Results -->
            <div *ngIf="!isLoadingPage && numResults > 0" class="row mt-5 product-page">
                <div class="col-12 h-100">
                    <ul>
                        <li *ngFor="let product of products">
                            <app-product (onSelect)="onProductSelected(product)" class="w-100"
                                         (onView)="viewProduct($event.websafeKey)"
                                         [product]="product"
                                         [isSelected]="selectedProduct && selectedProduct.websafeKey == product.websafeKey"
                            ></app-product>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="!isLoadingPage && !errorMessage && numResults > 0" class="row mt-3">
                <div class="col-12">
                    <app-page-indicator [minOffset]="minOffset"
                                        [currentOffset]="currentOffset"
                                        [numResults]="numResults"
                                        [maxIndicators]="isXSScreen()? 3 : 5"
                                        [pageSize]="pageSize"
                                        (pageSelected)="pageSelected.next($event)"
                                        (minOffsetChanged)="minOffset = $event">
                    </app-page-indicator>

                </div>
            </div>
            <div *ngIf="isLoadingPage" class="loader-container">
                <div class="loader">
                    <app-loader [stroke]="'var(--charcoal-grey-a50)'"></app-loader>
                </div>
            </div>

            <div *ngIf="!isLoadingPage && numResults == 0 && !errorMessage"
                 class="row h-75 no-results-found justify-content-center">
                <div class="col-8 col-md-6 no-results-found">
                    <div class="mx-auto text-center">
                        <fa-icon [icon]="faBoxOpen" size="4x"></fa-icon>
                        <div class="message mx-auto mt-1">
                            We could not find what you are looking for. Please refine your search terms.
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isLoadingPage && errorMessage" class="row no-results-found justify-content-center mt-5">
                <div class="col-4">
                    <div class="mx-auto text-center">
                        <fa-icon [icon]="faTimes" size="4x"></fa-icon>
                        <div class="message mx-auto mt-1">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- [END] Page Results -->
        </div>
    </div>
</div>
