<div class="overview">
    <div class="row">
        <div class="col-12">
            <h2>Stores</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="description">Manage your stores and check out their live purchasing streams.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button (click)="onNewStore()" class="d-flex btn-transparent">New store</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="w-100 d-inline-flex input-group store-search">
                <input #searchInput class="form-control border-right-0" placeholder="Find a store near..."
                       autocorrect="off" autocapitalize="off" spellcheck="off"
                       title="Search" [formControl]="searchControl"
                />
                <span class="input-group-append">
                    <div class="input-group-text text-primary bg-white border-left-0">
                        <fa-icon [icon]="faSearch"></fa-icon>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div class="row store-list mt-3">
        <div class="col-12">
            <div class="d-flex w-100">
                <app-page-indicator #indicator
                                    class="d-inline-flex"
                                    [minOffset]="0"
                                    [isLoading]="pageLoading"
                                    [currentOffset]="currentOffset"
                                    [numResults]="totalStores"
                                    [maxIndicators]="3"
                                    [pageSize]="25"
                                    (minOffsetChanged)="minOffset = $event"
                                    (pageSelected)="pageSelected.next($event)">
                </app-page-indicator>
                <span *ngIf="pageLoading && totalStores != null" class="d-inline-flex h-100 align-self-center ml-3" style="width: 35px; height: 35px">
                    <app-loader [width]="'30px'" height="'30px'" [stroke]="'var(--charcoal-grey-a50)'"></app-loader>
                </span>
            </div>
            <ul class="mt-2">
                <li class="store" *ngFor="let store of stores">
                    <app-store [store]="store"
                               [selected]="selectedStore && selectedStore.websafeKey == store.websafeKey"
                               (click)="onSelectStore.emit(store)"></app-store>
                </li>
            </ul>
        </div>
    </div>
</div>
