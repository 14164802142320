import {Component, EventEmitter, Input, Output} from "@angular/core";
import {UserLoadRequest} from "../dashboard";

@Component({
    selector: 'request-tile',
    templateUrl: './request-tile.component.html',
    styleUrls: ['./request-tile.component.css']
})
export class RequestTileComponent {

    @Input()
    request: UserLoadRequest

    @Output()
    approve: EventEmitter<UserLoadRequest> = new EventEmitter<UserLoadRequest>();

    @Output()
    reject: EventEmitter<UserLoadRequest> = new EventEmitter<UserLoadRequest>();

    @Output()
    onDrillDown: EventEmitter<UserLoadRequest> = new EventEmitter<UserLoadRequest>();

    get cardId() {
        return this.request.request.cardId;
    }

    get accountName() {
        return this.request?.account?.name || 'Deleted User'
    }

    get credit() {
        return this.request?.account?.credit;
    }

    get vendorName() {
        return this.request.request.vendor
    }

    get suburb() {
        return this.request.request?.store?.suburb || 'Unknown'
    }

    get requestAmount() {
        return this.request.request.amount
    }

    get paidByCard() {
        return this.request.request.paid
    }

    get requestStatus() {
        return this.request.request.status
    }

    get requestTimestamp() {
        return new Date(Date.parse(this.request.request.timestamp)).toLocaleString()
    }

    get customerName() {
        return this.request.request?.name
    }

    get customerPhone() {
        return this.request.request?.phone
    }

    get customerEmail() {
        return this.request.request?.email
    }

    get joinMailingList() {
        return this.request.request?.joinMailing || false
    }

    emitApprove(event) {
        this.approve.emit(this.request);
        event.stopPropagation()
    }

    emitReject(event) {
        this.reject.emit(this.request);
        event.stopPropagation();
    }
}
