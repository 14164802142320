/**
 * Created by Karl on 23/8/17.
 */

import {Component} from "@angular/core";
import {AdminService} from "../../admin.service";
import {Router} from "@angular/router";
@Component({
    selector: 'signup',
    template:
    `
    <form class="form">
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-md-9">
                <input class="form-control" type="text" [(ngModel)]="this.user.email" name="email" placeholder="Email"/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">First Name</label>
            <div class="col-md-9">
                <input class="form-control" type="text" [(ngModel)]="this.user.firstName" name="firstName" placeholder="First Name"/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Last Name</label>
            <div class="col-md-9">
                <input class="form-control" type="text" [(ngModel)]="this.user.lastName" name="lastName" placeholder="Last Name"/>
            </div>
        </div>
        <!-- TODO User form validators and css instead of this-->
        <p *ngIf="!passwordsMatch">Passwords don't match</p>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Password</label>
            <div class="col-md-9">
                <input class="form-control" type="password" [(ngModel)]="password" name="password" placeholder="Password"/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Confirm Password</label>
                <div class="col-md-9">
                    <input class="form-control" type="password" [(ngModel)]="confirmPassword" name="confirm-password" placeholder="Confirm Password"/>
                </div>
            </div>
        <div class="form-group row">
            <div class="offset-sm-9 col-sm-3">
                <button class="btn btn-primary btn-success" style="width: 100%" (click)="submitUser()">Submit</button>
            </div>
        </div>
    </form>
    `
})
// TODO Code duplication with verify-user-preview (pending) component.
export class SignUpComponent {
    constructor(private adminService: AdminService, private router: Router) {

    }
    // TODO Turn this into a type.
    user: any = {};

    passwordsMatch: boolean = true;

    set password(password: string) {
        this.user.password = password;
        this.passwordsMatch = this.user.password === this.user.confirmedPassword
    }

    set confirmPassword(password: string) {
        this.user.confirmedPassword = password;
        this.passwordsMatch = this.user.password === this.user.confirmedPassword
    }

    validPassword() {
        // TODO do pass word strength validation
        return this.passwordsMatch && this.user.password != null;
    }

    submitUser() {
        if (this.user.email == null || this.user.firstName == null || this.user.lastName == null ||
            !this.validPassword() ) {
            console.log('ERROR', this.user.email, this.user.firstName, this.user.lastName, this.passwordsMatch);
            return;
        }

        // If we are verifying a user-preview we should convert the pending user-preview.
        this.adminService.createUser(this.user).subscribe( success => this.onCreatedUser( success ) );
    }

    private onCreatedUser(success: any) {
        console.log( 'DEBUG', 'Successfully created Firebase User', success );
        this.router.navigate(['setup', 'verify-email'])
    }
}
