import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AdminService} from "../admin/admin.service";
import {LoadStatuses, StatusLoadResponse, UserLoadRequest} from "./dashboard";
import {first, switchMap, tap} from "rxjs/operators";
import {buildUrl, getAuthHeaders} from "../utils";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";


@Injectable()
export class CardsService {

    onRequestUpdated$: EventEmitter<UserLoadRequest> = new EventEmitter<UserLoadRequest>();

    constructor(private http: HttpClient, private adminService: AdminService) {
    }

    getLoadRequests(params: LoadStatuses, pageNumber: number) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getLoadRequestsFromServer(token, params, pageNumber)),
            first()
        )
    }

    private getLoadRequestsFromServer(token: string, params: LoadStatuses, pageNumber: number) : Observable<StatusLoadResponse> {
        const url = buildUrl(environment.hostUrl, ['cards', 'status_loads'], {});
        return this.http.post<StatusLoadResponse>(url, {statuses: params, pageNumber: pageNumber}, {headers: getAuthHeaders(token)})
    }

    updateRequest(cardId: string, amount: number, status: string) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.updateRequestOnServer(token, cardId, amount, status)),
            first(),
            tap(request => {
                this.onRequestUpdated$.emit(request);
            }))
    }

    private updateRequestOnServer(token: string, cardId: string, amount: number, status: string) {
        const url = buildUrl(environment.hostUrl, ['cards', 'update_request'], {});
        const params = {cardId: cardId, amount: amount, status: status}
        return this.http.post<UserLoadRequest>(url, params, {headers: getAuthHeaders(token)})
    }
}
