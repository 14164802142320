/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />
import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MapsAPILoader} from '@agm/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faMapMarker} from '@fortawesome/free-solid-svg-icons';
import {Store} from '../index'
import {
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_DOWN,
    KEY_UP,
    KEY_SHIFT,
    KEY_CAPS,
    KEY_META,
    KEY_CTRL, KEY_ALT, KEY_TAB
} from '../../constants';
import {StoreService} from '../store.service';
import {first, tap} from 'rxjs/operators';
import {INTERNAL_ERROR_MSG, INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE, SERVICE_UNAVAILABLE_MSG} from '../../utils';
import {HttpErrorResponse} from '@angular/common/http';
import {fadeInOut} from '../animations';


@Component({
    selector: 'app-new-store',
    templateUrl: './new-store.component.html',
    styleUrls: ['./new-store.component.css'],
    animations: [fadeInOut]
})
export class NewStoreComponent implements OnInit {

    faMapMarker = faMapMarker;

    searchControl = new FormControl();
    vendorControl = new FormControl('', Validators.required);

    storeActive: boolean = true;

    formMode: boolean = true;
    loading: boolean = false;

    store: Store;

    errorMessage: string = 'Could not create store at this time. Please try again later.';

    @ViewChild('searchInput', {static: false})
    searchInput;

    constructor(private mapsAPILoader: MapsAPILoader,
                public activeModal: NgbActiveModal,
                private storeService: StoreService,
                private ngZone: NgZone) {

    }

    ngOnInit() {
        this.mapsAPILoader.load().then( () => {
            console.log('DEBUG', 'loaded maps new store');
            const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, {
                types: []
            });
            autocomplete.setComponentRestrictions(
                {'country': ['au', 'nz']}
            );
            autocomplete.addListener('place_changed', () => this.executeAutocomplete(autocomplete));
        });
        this.vendorControl.valueChanges
            .subscribe(vendor => {
                if (this.store != null) {
                    this.store = {
                        websafeKey: this.store.websafeKey,
                        address: this.store.address,
                        suburb: this.store.suburb,
                        lat: this.store.lat,
                        lng: this.store.lng,
                        vendor: vendor,
                        active: this.storeActive
                    };
                } else {
                    this.store = {
                        websafeKey: null,
                        address: null,
                        suburb: null,
                        lat: null,
                        lng: null,
                        vendor: vendor,
                        active: this.storeActive
                    };
                }
            })
    }

    executeAutocomplete(autocomplete: google.maps.places.Autocomplete) {
        this.ngZone.run(() => {
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                const location = place.geometry.location;
                if (location === undefined || location === null) {
                    return;
                }

                const address = place.formatted_address;
                if (address === undefined || address === null) {
                    return;
                }

                const suburbComponent = place.address_components
                    .find( component => component.types.includes("locality"));
                if (suburbComponent === undefined || suburbComponent === null) {
                    return;
                }

                this.store = {
                    websafeKey: null,
                    address: address,
                    suburb: suburbComponent.long_name,
                    lat: location.lat(),
                    lng: location.lng(),
                    vendor: this.store != null ? this.store.vendor : null,
                    active: this.storeActive
                };
            }
        )
    }

    get isValidStore() {
        return this.store != null && this.store.vendor != null && this.store.vendor != '' && this.store.address != null;
    }

    onKeydown(event: KeyboardEvent) {
        switch(event.key) {
            case KEY_ENTER:
            case KEY_DOWN:
            case KEY_UP:
            case KEY_LEFT:
            case KEY_RIGHT:
            case KEY_SHIFT:
            case KEY_CAPS:
            case KEY_META:
            case KEY_CTRL:
            case KEY_ALT:
            case KEY_TAB:
                return;
            default:
                this.store = null;
        }
    }

    onCreateStore() {
        if ( this.store == null ) {
            return;
        }
        this.loading = true;
        this.storeService.createStore(this.store).pipe(
            first(),
            tap( _ => this.loading = false)
        ).subscribe(
            store => this.onStoreCreated(store),
            error  => this.handleError(error)
        )
    }

    onStoreCreated(store: Store) {
        this.store = store;
        this.formMode = false;
    }

    onGoToStore() {
        if (this.store == null) {
            this.activeModal.close(true);
            return;
        }

        this.activeModal.close(this.store);
    }

    handleError(error) {
        this.loading = false;
        if (error instanceof HttpErrorResponse) {
            this.errorMessage = this.getHttpErrorMessage(error);
        } else {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
        }

    }

    private getHttpErrorMessage(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }
}
