import {HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Error} from 'tslint/lib/error';

export const KEY_BACKSPACE = 'Backspace';
export const KEY_ENTER = 'Enter';

export const SERVICE_UNAVAILABLE = 503;
export const SERVICE_UNAVAILABLE_MSG = 'Service unavailable. Please try again later.';

export const INTERNAL_SERVER_ERROR = 500;
export const INTERNAL_ERROR_MSG = 'Something went wrong internally. IT support has been contacted.';


export function validateNumberBounds(name: string,
                                     num: number,
                                     lowerBound = Number.MIN_VALUE,
                                     upperBound = Number.MAX_VALUE,
                                     includeBounds = false) {
    if (includeBounds && (num < lowerBound || upperBound < num)) {
        const msg = num < lowerBound ? `${num} < ${lowerBound}` : `${upperBound} < ${num}`;
        throw new Error(`Expected ${lowerBound} <= ${name} <= ${upperBound}. Found ${msg}`)
    }

    if (!includeBounds && (num <= lowerBound || upperBound <= num)) {
        const msg  = num <= lowerBound ? `${num} <= ${lowerBound}` : `${upperBound} <= ${num}`;
        throw new Error(`Expected ${lowerBound} < ${name} < ${upperBound}. Found ${msg}`)
    }
}

export function buildUrl(baseUrl: string, paths: string[], params: {[key: string]: string}): string {
    return baseUrl + '/' + paths.join('/') + buildQuery(params);
}

export function buildQuery(params: {[key: string]: string}): string {
    const query: string =  Object
        .entries<string>(params)
        .map(([key, value]) => encodeQueryUri(key, value))
        .join('&')
        .trim();

    return query ? '?' + query : '';
}

export function encodeQueryUri(key: string, value: string) {
    if (!key) {
        return '';
    }

    return key + '=' + encodeURIComponent(value);
}


export function getAuthHeaders(token: string) {
    let headers: HttpHeaders = new HttpHeaders();
    if (token) {
        headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
}


// TODO Deprecated, remove.
export function getDefaultProductImageUrl() {
    let imageBucket: string = '';
    if (environment.hostUrl.includes('localhost')) {
        imageBucket = 'wlt-aus-staging.appspot.com'
    } else {
        imageBucket = environment.hostUrl.replace('https://', '');
    }
    return 'https://storage.googleapis.com/' + imageBucket + '/WLT/assets/default-productFormData.png'
}
